import { CardActions, IconButton, Icon, Tooltip, Box, Button, Typography } from '@mui/material';
import { EditOutlined } from "@mui/icons-material";
import { useMemo } from "react";

const EventActions = ({ eventId, ticketUrl, shopUrl, watchUrl, isPastEvent, position, isTrendingOrAdded, handleAddEvent, showRecommendedCalendars, setShowRecommendedCalendars, isEmbedded, isOwnEvent, isGroup, setOpenEditEventDialog }) => {
   const isAutomatedEvent = useMemo(() => !!eventId.match(/[a-fA-F0-9]{24}/), [eventId]);
   return (
        <CardActions id={"calendar-event-add-container"} sx={{ p: 0, mt: isTrendingOrAdded ? 1 : 2, transform: "translateX(-9px)", justifyContent: "space-between" }}>
            <Box sx={{ display: 'flex', alignItems: "center" }}>
                {ticketUrl && !isPastEvent && (
                    <Tooltip title="Tickets">
                        <IconButton color="primary" size="medium" as="a" href={ticketUrl} target="_blank" sx={{ display: 'flex' }} onClick={(e) => e.stopPropagation()}>
                            <Icon baseClassName="material-symbols-outlined" fontSize="medium" sx={{ fontWeight: "300" }}>
                                confirmation_number
                            </Icon>
                        </IconButton>
                    </Tooltip>
                )}

                {shopUrl && (
                    <Tooltip title="Shop">
                        <IconButton color="primary" size="medium" as="a" href={shopUrl} target="_blank" sx={{ display: 'flex' }} onClick={(e) => e.stopPropagation()}>
                            <Icon baseClassName="material-symbols-outlined" fontSize="medium" sx={{ fontWeight: "300" }}>
                                apparel
                            </Icon>
                        </IconButton>
                    </Tooltip>
                )}

                {watchUrl && (
                    <Tooltip title="Watch">
                        <IconButton color="primary" size="medium" as="a" href={watchUrl} target="_blank" sx={{ display: 'flex' }} onClick={(e) => e.stopPropagation()}>
                            <Icon baseClassName="material-symbols-outlined" fontSize="medium" sx={{ fontWeight: "300" }}>
                                smart_display
                            </Icon>
                        </IconButton>
                    </Tooltip>
                )}

                <Tooltip title="Download">
                    <IconButton data-testid={`calendar-event-add-${position}`} onClick={handleAddEvent} color="primary" size="medium" sx={{ display: 'flex' }}>
                        <Icon baseClassName="material-symbols-outlined" fontSize="medium" sx={{ fontWeight: "300" }}>
                            download
                        </Icon>
                    </IconButton>
                </Tooltip>
            </Box>

            {!isEmbedded && (!isOwnEvent || !isAutomatedEvent) && (
                <Box sx={{ display: 'flex', alignItems: "center" }}>
                    <Button
                        component="div"
                        onClick={setShowRecommendedCalendars}
                        data-testid={`calendar-event-discover-${position}`}
                        sx={{
                            textTransform: "none",
                            fontSize: "inherit",
                            padding: 1,
                            display: "block",
                            width: "fit-content",
                        }}
                        disabled={showRecommendedCalendars}
                    >
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <Icon fontSize="medium" sx={{ fontWeight: "200" }} baseClassName="material-symbols-outlined">auto_awesome</Icon>
                            <Typography variant="subtitle2">Related Calendars</Typography>
                        </Box>
                    </Button>
                </Box>
            )}
            {isOwnEvent && isAutomatedEvent && !isGroup &&
                <Button
                    variant="contained"
                    size="small"
                    startIcon={<EditOutlined sx={{ fontSize: "1rem !important" }} />}
                    sx={{ backgroundColor: "#8E8E93", height: "24px", '.MuiButton-startIcon': { marginRight: "5px" } }}
                    component="label"
                    onClick={() => setOpenEditEventDialog(true)}
                >
                    Update Event
                </Button>
            }
        </CardActions>
    )
}

export default EventActions;