import axios from "axios";
import { useContext } from "react";
import { getApiRoute, getPageRoute } from "src/services";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "src/utils/AuthContext.js";

// Mark calendar deleted from user's createdCalendars array or delete the group
export function useDeleteCalendarGroup() {
  const navigate = useNavigate();
  const { refetchAuth } = useContext(AuthContext);
  return useMutation(async ({ typeGroup, calendar }) => {
    try {
      if (typeGroup) {
        // Delete the groups
        await axios.post(
          getApiRoute("user", "DELETE_GROUP"),
          { groupId: calendar._id },
          { withCredentials: true }
        );
        console.log("group deleted");
        navigate(getPageRoute("library", "LIBRARY", {}, { tab: "groups" }));
      } else {
        // Delete the calendar
        await axios.post(
          getApiRoute("calendar", "DELETE_CALENDAR", {
            calendarId: calendar._id,
          }),
          null,
          { withCredentials: true }
        );
        console.log("calendar deleted");
        navigate(
          getPageRoute("library", "LIBRARY", {}, { tab: "imports" }));
      }
      refetchAuth();
    } catch (error) {
      console.error(error);
    }
  });
}