import axios from "axios";
import { getApiRoute } from "src/services";
import { useQuery } from "@tanstack/react-query";
import { groupBy } from "lodash";

export async function getGoogleCalendarStatus({ user }) {
  let response = { data: null };
  if (user) {
    response = await axios.get(
      getApiRoute("library", "GET_GOOGLE_CALENDAR_STATUS"),
      {
        withCredentials: true,
      }
    );
  }
  const calendarsById = groupBy(response.data.calendars, (item) => item.id);
  const groupsById = groupBy(response.data.groups, (item) => item.id);
  return { ...response.data, calendarsById, groupsById };
}

export const useGoogleCalendarStatus = (
  params = {
    user: null,
  },
  options = {}
) => {
  return useQuery(
    ["user-migration-status"],
    () => getGoogleCalendarStatus(params),
    {
      ...options,
      // Only retry if there was an error and it was not a 404
      retry: (failureCount, error) => {
        // If the error response is a 404, then don't retry
        if (error.response?.status === 404) {
          return 0;
        }
        return true;
      },
    }
  );
};
