import axios from "axios";
import { getApiRoute } from "src/services";
import { useMutation } from "@tanstack/react-query";

async function subscribeCalendar({ calendarId, handle }) {
  await axios.post(
    getApiRoute("user", "ADD_CALENDAR"),
    { calendarId, handle },
    { withCredentials: true }
  );
}

export function useSubscribeCalendar() {
  return useMutation(subscribeCalendar);
}  