import { useState, useEffect, useContext } from "react";
import axios from "axios";
import Navbar from "./blocks/Navbar.js";
import { Typography, Box, Paper, Tabs, Tab, Icon } from "@mui/material";
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";
import CalendarList from "./blocks/CalendarList.js";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import ActionCard from "./blocks/ActionCard.js";
import LoadingAnimation from "./blocks/LoadingAnimation.js";
import {
  copyCalendarLinkToClipboard,
  getCalendarApiUrl,
  getWebcalFormat,
  handleCustomerPortal,
  handleOpenWebcal,
  handleSubscribeExperience,
  handleUnsubscribe,
} from "src/utils/Utils.js";
import { AuthContext } from "src/utils/AuthContext.js";
import {
  getPageRoute,
  useGetUserCalendars,
  useUnsubscribeGroup,
  useUnsubscribeCalendar,
  useRemoveFromGroup,
} from "src/services";
import Footer from "./blocks/Footer.js";
import { useTheme } from "@emotion/react";
import { revealNavbar, sortAlphabetically } from "src/utils/Utils.js";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
import ShareDialog from "./blocks/dialogs/ShareDialog.js";
import { useGroupDialogs } from "src/utils/useGroupDialogs.js";
import NewGroupDialog from "./blocks/dialogs/NewGroupDialog.js";
import AddToGroupDialog from "./blocks/dialogs/AddToGroupDialog.js";
import { Helmet } from "react-helmet";
import DeleteCalendarDialog from "./blocks/dialogs/DeleteCalendarDialog.js";
import DeleteGroupDialog from "./blocks/dialogs/DeleteGroupDialog.js";
import { useDeleteCalendarGroup } from "src/services/mutations/useDeleteCalendarGroup.js";
import { useGoogleCalendarStatus } from "src/services/queries/useGoogleCalendarStatus.js";
import { useForceRefreshGoogleCalendar } from "src/services/mutations/useForceRefreshGoogleCalendar.js";

function Library({ user }) {
  const [importedCalendars, setImportedCalendars] = useState([]);
  const [createdGroups, setCreatedGroups] = useState([]);
  const [addedItems, setAddedItems] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [searchParams] = useSearchParams();
  let { tab } = searchParams;
  tab = tab || new URLSearchParams(window.location.search).get("tab") || "calendars";
  const [activeTab, setActiveTab] = useState(tab);
  if (!tab || activeTab) window.history.replaceState({}, "", `?tab=${activeTab}`);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openGroup, setOpenGroup] = useState(false);
  const [deleteText, setDeleteText] = useState("");
  const [deleteMessage, setDeleteMessage] = useState("");
  const [targetDeleteCalendar, setTargetDeleteCalendar] = useState(null);
  const { refetchAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const [shareDialogCalendar, setShareDialogCalendar] = useState(null);
  const {
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarSeverity,
    showSnackBar,
  } = useSnackbarContext();
  const { mutateAsync: unsubscribeCalendar } = useUnsubscribeCalendar();
  const { mutateAsync: removeFromGroup } = useRemoveFromGroup();
  const { mutateAsync: unsubscribeGroup } = useUnsubscribeGroup();
  const { data: googleCalendarStatus, refetch: refetchGoogleCalendarStatus, isLoading: isLoadingGoogleCalendarStatus } = useGoogleCalendarStatus({ user });
  const { handleForceRefreshGoogleCalendar, isLoading: isLoadingForceRefreshGoogleCalendarRequest, forceRefreshCalendarId } = useForceRefreshGoogleCalendar({ refetchGoogleCalendarStatus });
  const isLoadingForceRefreshGoogleCalendar = isLoadingForceRefreshGoogleCalendarRequest || (isLoadingGoogleCalendarStatus && user?.hasValidGoogleConnection);
  const {
    data: userCalendars,
    refetch: refetchUserCalendars,
    isLoading,
  } = useGetUserCalendars(
    { user },
    {
      onSuccess: (data) => {
        setImportedCalendars(
          sortAlphabetically(data.createdCalendars, "name") ?? []
        );
        setCreatedGroups(sortAlphabetically(data.createdGroups, "name") ?? []);
        setAddedItems(
          sortAlphabetically(
            [...(data.addedCalendars ?? []), ...(data.addedGroups ?? [])],
            "name"
          )
        );
        setInitialLoad(false);
      },
    }
  );
  const {
    openAddToGroup,
    openNewGroup,
    setOpenAddToGroup,
    setOpenNewGroup,
    handleGroupDialog,
    handleAddToGroup,
    handleCreateGroup,
    handleMessage,
    targetCalendar,
  } = useGroupDialogs({
    setCreatedGroups,
    moveCalendarToGroup: true,
  });

  useEffect(() => {
    refetchUserCalendars();
  }, []);

  const { mutateAsync: handleDeleteMutation, isLoading: isLoadingDelete } = useDeleteCalendarGroup()

  const handleDelete = async (event) => {
    event.preventDefault();
    if (deleteText !== "DELETE") {
      setDeleteMessage("Please check for typos.");
      return;
    }
    const typeGroup = targetDeleteCalendar?.calendars;
    await handleDeleteMutation({
      typeGroup,
      calendar: targetDeleteCalendar
    });
    refetchUserCalendars();
    handleCloseDeleteDialog();
  }

  // Open delete dialog box
  const handleClickOpenDeleteDialog = (calendar) => {
    setTargetDeleteCalendar(calendar);
    const typeGroup = calendar?.calendars;
    if (typeGroup) {
      setOpenGroup(true);
    } else {
      setOpenCalendar(true);
    }
  };

  // Close delete dialog box
  const handleCloseDeleteDialog = (event) => {
    event?.preventDefault();
    setDeleteText("");
    const typeGroup = targetDeleteCalendar?.calendars;
    if (typeGroup) {
      setOpenGroup(false);
    } else {
      setOpenCalendar(false);
    }
    setTargetDeleteCalendar(null);
  };

  const handleResubscribe = (calendar) => {
    const calendarApiUrl = getCalendarApiUrl({
      calendar,
      user,
    });
    const defaultFn = () =>
      handleOpenWebcal(
        getWebcalFormat(calendarApiUrl),
        calendar,
        navigate
      );
    handleSubscribeExperience({
      user,
      navigate,
      calendar,
      defaultFn,
    });
  }

  const isDisabledForceRefresh = (calendar) => {
    const googleCalendarStatusId =
      googleCalendarStatus?.calendarsById?.[calendar._id]?.[0] ??
      googleCalendarStatus?.groupsById?.[calendar._id]?.[0] ??
      null;
    const isSyncing = googleCalendarStatusId?.status === "syncing"
    const isRefreshing = isLoadingForceRefreshGoogleCalendar && forceRefreshCalendarId === calendar._id;
    return isRefreshing || isSyncing
  }

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
        day: "2-digit",
      }).format(date);
    } catch (error) {
      console.error(error);
      showSnackBar(
        setSnackbarOpen,
        setSnackbarMessage,
        setSnackbarSeverity,
        "This browser isn't displaying dates correctly. Please use another browser.",
        "error"
      );
      return "Unknown date/time";
    }
  };

  const handleTabChange = async (event, key) => {
    revealNavbar();
    if (key === "groups") {
      refetchUserCalendars();
    }
    setActiveTab(key);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Helmet>
        <title>Library - Stanza</title>
        <meta
          name="description"
          content="All the calendars you've added or imported."
        />
      </Helmet>
      <Navbar user={user} />
      <Box
        sx={{
          margin: "auto",
          maxWidth: { xs: "calc(100% - 2rem)", sm: "600px" },
          minWidth: { xs: "calc(100% - 2rem)", sm: "600px" },
          marginTop: 0,
          flexGrow: 1,
          p: 2,
        }}
      >
        <Typography
          as="h1"
          variant="title"
          sx={{ mb: 1, mt: { md: 3 }, fontWeight: "600" }}
        >
          Your Library
        </Typography>
        <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 3 }}>
          Everything you've created or added.
        </Typography>
        <Tabs
          id="library-tabs"
          data-testid="library-tabs"
          value={activeTab}
          onChange={handleTabChange}
          variant={"fullWidth"}
        >
          <Tab label="Calendars" value="calendars" disableRipple />
          <Tab label="Groups" value="groups" disableRipple />
          <Tab label="Imports" value="imports" disableRipple />
        </Tabs>
        <Box role="tabpanel" hidden={activeTab !== "calendars"}>
          {user.consumerSubscription?.isCancelled ? (
            <Paper
              variant="outlined"
              sx={{ p: 2, mt: 2 }}
              data-testid="library-canceled-calendar-copy"
            >
              <Typography variant="subtitle2" sx={{ lineHeight: 1.5 }}>
                ⚠️ Your plan will be canceled, but is still available until the
                end of your billing period on{" "}
                {user.consumerSubscription
                  ? formatDate(user.consumerSubscription?.expiresAt)
                  : ""}
                . At that point, you can add only 2 calendars.{" "}
                <strong>
                  Any extra calendars will be automatically deleted.
                </strong>{" "}
                <br /> <br />
                If you change your mind, you can{" "}
                <Link
                  onClick={handleCustomerPortal}
                  style={{ textDecoration: "none" }}
                >
                  renew
                </Link>{" "}
                your subscription to keep all your calendars.
              </Typography>
            </Paper>
          ) : null}
          {isLoading || initialLoad ? (
            <LoadingAnimation />
          ) : addedItems.length > 0 ? (
            <CalendarList
              id={"calendars"}
              calendars={addedItems}
              {...(!user?.hasValidGoogleConnection
                ? {
                  buttonIcon: (props) => (
                    <Icon
                      baseClassName="material-symbols-outlined"
                      {...props}
                    >
                      event_repeat
                    </Icon>
                  ),
                  buttonText: "Resubscribe",
                  buttonAction: handleResubscribe,
                }
                : {})}
              googleCalendarStatus={googleCalendarStatus}
              isLoadingForceRefreshGoogleCalendar={isLoadingForceRefreshGoogleCalendar}
              forceRefreshCalendarId={forceRefreshCalendarId}
              threeDotsMenu={[
                {
                  icon: (props) => (
                    <Icon baseClassName="material-symbols-outlined" {...props}>
                      create_new_folder
                    </Icon>
                  ),
                  name: "Add to Group",
                  action: handleGroupDialog,
                },
                user?.hasValidGoogleConnection ?
                  {
                    icon: (props) => (
                      <Icon
                        baseClassName="material-symbols-outlined"
                        {...props}
                      >
                        refresh
                      </Icon>
                    ),
                    name: "Force Refresh",
                    action: handleForceRefreshGoogleCalendar,
                    isDisabled: isDisabledForceRefresh
                  } : null,
                {
                  icon: (props) => (
                    <Icon baseClassName="material-symbols-outlined" {...props}>
                      link
                    </Icon>
                  ),
                  name: "Copy Link",
                  action: (calendar) => {
                    copyCalendarLinkToClipboard({
                      inGroup: false,
                      user,
                      calendar,
                      showSnackBar,
                      setSnackbarOpen,
                      setSnackbarMessage,
                      setSnackbarSeverity,
                    });
                  },
                },
                {
                  icon: (props) => (
                    <Icon baseClassName="material-symbols-outlined" {...props}>
                      share
                    </Icon>
                  ),
                  name: "Share",
                  action: (calendar) => {
                    setShareDialogCalendar(calendar);
                  },
                },
                {
                  icon: (props) => (
                    <Icon baseClassName="material-symbols-outlined" {...props}>
                      delete
                    </Icon>
                  ),
                  name: "Delete",
                  color: "error",
                  action: (calendar) =>
                    handleUnsubscribe({
                      user,
                      calendar,
                      unsubscribeGroup,
                      refetchUserCalendars,
                      createdGroups: userCalendars.createdGroups,
                      removeFromGroup,
                      unsubscribeCalendar,
                      onUnsubscribed: async () => {
                        setAddedItems(
                          addedItems.filter((c) => c._id !== calendar._id)
                        );
                        refetchAuth();
                      },
                      showSnackBar,
                      setSnackbarOpen,
                      setSnackbarMessage,
                      setSnackbarSeverity,
                    }),
                },
              ].filter(Boolean)}
            />
          ) : (
            <ActionCard
              title="No calendars"
              testid="user-no-added-calendar"
              description="You haven't added any calendars yet."
              primaryButtonCTA="Discover calendars"
              primaryAction={() => navigate(getPageRoute("home", "HOME"))}
              iconName="calendar_month"
            />
          )}
        </Box>
        <Box role="tabpanel" hidden={activeTab !== "groups"}>
          {user.consumerSubscription?.isCancelled ? (
            <Paper
              variant="outlined"
              sx={{ p: 2, mt: 2 }}
              data-testid="library-canceled-group-copy"
            >
              <Typography variant="subtitle2" sx={{ lineHeight: 1.5 }}>
                ⚠️ Your plan will be canceled, but is still available until the
                end of your billing period on{" "}
                {user.consumerSubscription
                  ? formatDate(user.consumerSubscription?.expiresAt)
                  : ""}
                . At that point, you can no longer add any groups.{" "}
                <strong>Any groups will be automatically deleted.</strong>{" "}
                <br /> <br />
                If you change your mind, you can{" "}
                <Link
                  onClick={handleCustomerPortal}
                  style={{ textDecoration: "none" }}
                >
                  renew
                </Link>{" "}
                your subscription to keep all your calendars.
              </Typography>
            </Paper>
          ) : null}
          {isLoading || initialLoad ? (
            <LoadingAnimation />
          ) : createdGroups.length > 0 ? (
            <CalendarList
              id={"groups"}
              calendars={createdGroups}
              {...(!user?.hasValidGoogleConnection
                ? {
                  buttonIcon: (props) => (
                    <Icon
                      baseClassName="material-symbols-outlined"
                      {...props}
                    >
                      event_repeat
                    </Icon>
                  ),
                  buttonText: "Resubscribe",
                  buttonAction: handleResubscribe
                }
                : {})}
              googleCalendarStatus={googleCalendarStatus}
              isLoadingForceRefreshGoogleCalendar={isLoadingForceRefreshGoogleCalendar}
              forceRefreshCalendarId={forceRefreshCalendarId}
              threeDotsMenu={[
                {
                  icon: (props) => <EditOutlined {...props} />,
                  name: "Edit",
                  action: (calendar) =>
                    navigate(
                      getPageRoute("calendar", "CALENDAR_SETTINGS", {
                        handle: user.handle,
                        calendarId: calendar._id,
                      }),
                      {
                        state: { calendar: calendar },
                      }
                    )
                },
                user?.hasValidGoogleConnection
                  ? {
                    icon: (props) => (
                      <Icon
                        baseClassName="material-symbols-outlined"
                        {...props}
                      >
                        refresh
                      </Icon>
                    ),
                    name: "Force Refresh",
                    action: handleForceRefreshGoogleCalendar,
                    isDisabled: isDisabledForceRefresh
                  }
                  : null,
                {
                  icon: (props) => (
                    <Icon baseClassName="material-symbols-outlined" {...props}>
                      link
                    </Icon>
                  ),
                  name: "Copy Link",
                  action: (calendar) => {
                    copyCalendarLinkToClipboard({
                      inGroup: false,
                      user,
                      calendar,
                      showSnackBar,
                      setSnackbarOpen,
                      setSnackbarMessage,
                      setSnackbarSeverity,
                    });
                  },
                },
                {
                  icon: (props) => (
                    <Icon baseClassName="material-symbols-outlined" {...props}>
                      share
                    </Icon>
                  ),
                  name: "Share",
                  action: (calendar) => {
                    setShareDialogCalendar(calendar);
                  },
                },
                {
                  icon: (props) => (
                    <Icon baseClassName="material-symbols-outlined" {...props}>
                      delete
                    </Icon>
                  ),
                  name: "Delete",
                  color: "error",
                  action: handleClickOpenDeleteDialog,
                },
              ].filter(Boolean)}
            />
          ) : (
            <ActionCard
              title="No groups"
              testid="user-no-created-group"
              description="You haven't created any groups yet."
              primaryButtonCTA="Discover calendars"
              primaryAction={() => navigate(getPageRoute("home", "HOME"))}
              iconName="folder"
            />
          )}
        </Box>
        <Box role="tabpanel" hidden={activeTab !== "imports"}>
          {user.createdCalendars.length > user.maxNumCreatedCalendars ? (
            <Paper
              variant="outlined"
              sx={{ p: 2, mt: 2 }}
              data-testid="library-canceled-creator-copy"
            >
              <Typography variant="subtitle2" sx={{ lineHeight: 1.5 }}>
                ⚠️ Your current plan allows you to import{" "}
                {user.maxNumCreatedCalendars} calendar
                {user.maxNumCreatedCalendars === 1 ? "" : "s"}. Please delete{" "}
                {importedCalendars.length - user.maxNumCreatedCalendars}{" "}
                calendar
                {importedCalendars.length - user.maxNumCreatedCalendars === 1
                  ? ""
                  : "s"}{" "}
                by{" "}
                {user.consumerSubscription
                  ? formatDate(user.creatorSubscription?.expiresAt)
                  : ""}
                .{" "}
                <strong>
                  Any extra calendars will be automatically deleted.
                </strong>{" "}
                <br />
                <br />
                If you change your mind, you can{" "}
                <Link
                  onClick={handleCustomerPortal}
                  style={{ textDecoration: "none" }}
                >
                  update your subscription
                </Link>{" "}
                to keep all your calendars.
              </Typography>
            </Paper>
          ) : null}
          {isLoading || initialLoad ? (
            <LoadingAnimation />
          ) : importedCalendars.length === 0 ? (
            <ActionCard
              title="No imports"
              testid="user-no-imported-calendar"
              description="You haven't imported any calendars yet."
              primaryButtonCTA="Import calendar"
              primaryAction={() => navigate(getPageRoute("calendar", "IMPORT"))}
              iconName="calendar_apps_script"
            />
          ) : (
            <CalendarList
              id={"imports"}
              calendars={importedCalendars}
              buttonIcon={(props) => <EditOutlined {...props} />}
              buttonText="Edit"
              buttonAction={(calendar) =>
                navigate(
                  getPageRoute("calendar", "CALENDAR_SETTINGS", {
                    handle: user.handle,
                    calendarId: calendar._id,
                  }),
                  {
                    state: { calendar: calendar },
                  }
                )
              }
              threeDotsMenu={[
                {
                  icon: (props) => (
                    <Icon baseClassName="material-symbols-outlined" {...props}>
                      link
                    </Icon>
                  ),
                  name: "Copy Link",
                  action: (calendar) => {
                    copyCalendarLinkToClipboard({
                      inGroup: false,
                      user,
                      calendar,
                      showSnackBar,
                      setSnackbarOpen,
                      setSnackbarMessage,
                      setSnackbarSeverity,
                    });
                  },
                },
                {
                  icon: (props) => (
                    <Icon baseClassName="material-symbols-outlined" {...props}>
                      delete
                    </Icon>
                  ),
                  name: "Delete",
                  color: "error",
                  action: handleClickOpenDeleteDialog,
                },
              ]}
            />
          )}
        </Box>
      </Box>
      <ShareDialog
        calendar={shareDialogCalendar}
        handle={shareDialogCalendar?.handle}
        open={Boolean(shareDialogCalendar)}
        setOpen={() => setShareDialogCalendar(null)}
        as="Hidden"
      />

      <NewGroupDialog
        open={openNewGroup}
        handleClose={() => setOpenNewGroup(false)}
        handleCreateGroup={handleCreateGroup}
        user={user}
        calendarId={targetCalendar._id}
        handleMessage={handleMessage}
      />
      <AddToGroupDialog
        createdGroups={createdGroups}
        open={openAddToGroup}
        handleClose={() => setOpenAddToGroup(false)}
        handleCreate={() => {
          setOpenAddToGroup(false);
          setOpenNewGroup(true);
        }}
        handleAdd={handleAddToGroup}
      />
      <DeleteCalendarDialog
        open={openCalendar}
        handleClose={handleCloseDeleteDialog}
        handleDelete={handleDelete}
        isLoading={isLoadingDelete}
        deleteText={deleteText}
        deleteMessage={deleteMessage}
        setDeleteText={setDeleteText}
      />
      <DeleteGroupDialog
        open={openGroup}
        handleClose={handleCloseDeleteDialog}
        handleDelete={handleDelete}
        isLoading={isLoadingDelete}
        deleteText={deleteText}
        deleteMessage={deleteMessage}
        setDeleteText={setDeleteText}
      />
      <Footer />
    </div>
  );
}

export default Library;
