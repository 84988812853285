import React, { createContext, useState, useContext, useEffect } from "react";
import { AuthContext } from "./AuthContext.js";
import { Link } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { getPageRoute } from "src/services/Config.js";
import { customEvent } from "src/utils/gtag.js";

const TopNavbarContext = createContext();

export const useTopNavbar = () => {
  return useContext(TopNavbarContext);
};

export const TopNavbarProvider = ({ children }) => {
  const [showTopNavbar, setShowTopNavbar] = useState(false);
  const [topNavbarContent, setTopNavbarContent] = useState(null);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    // Determine the status based on user properties
    const getUserStatus = () => {
      if (user?.needsConsumerCalendarUpgrade) {
        return "UPGRADE_REQUIRED";
      }
      if (user?.hasInvalidRefreshToken) {
        return "INVALID_REFRESH_TOKEN";
      }
      if ((user?.provider === "google" || user?.provider === "email") && !user?.hasValidGoogleConnection) {
        return "GOOGLE_CONNECT";
      }
      return "NO_NAVBAR";
    };

    switch (getUserStatus()) {
      case "UPGRADE_REQUIRED":
        setTopNavbarContent(
          <>
            {" "}
            You've hit the limit of free calendar subscriptions. Upgrade to&nbsp;
            <Link
              component={RouterLink}
              to={getPageRoute("upgrade", "UPGRADE_PLUS")}
              color="inherit"
            >
              Stanza+
            </Link>
            &nbsp;for unlimited calendar subscriptions.
          </>
        );
        setShowTopNavbar(true);
        break;

      case "GOOGLE_CONNECT":
        setTopNavbarContent(
          <>
            {" "}
            🎉 <b>New:</b> &nbsp;
            <Link
              component={RouterLink}
              onClick={() => {
                customEvent({
                  name: "nav_google_calendar_steps_top_banner",
                  category: "GoogleCalendarExperience",
                  label: "Navigate to Google Calendar Steps from Top Banner Click",
                  data: {
                    user_id: user._id,
                    user_email: user.email,
                    user_handle: user.handle ?? "no_handle",
                  },
                });
              }}
              to={getPageRoute("help", "SUBSCRIBING_GOOGLE_CALENDAR_STEPS")}
              color="inherit"
            >
              Connect your Google Calendar
            </Link>
            &nbsp;for faster updates, across all devices. &nbsp;
            <Link
              component={RouterLink}
              onClick={() => window.open("https://stanza-1.gitbook.io/stanza-help-center/for-subscribers/stanzaplus/google-connect", "_blank")}
              color="inherit"
            >
              Watch a demo
            </Link>
            &nbsp;of how it works.
          </>
        );
        setShowTopNavbar(true);
        break;

      case "INVALID_REFRESH_TOKEN":
        setTopNavbarContent(
          <>
            {" "}
            ‼️ <b>Important:</b> &nbsp;
            There is an issue with your Google Calendar connection.
            &nbsp;
            <Link
              component={RouterLink}
              to={getPageRoute("help", "CONNECT_GOOGLE_CALENDAR")}
              color="inherit"
            >
              Reconnect now.
            </Link>

          </>
        );
        setShowTopNavbar(true);
        break;

      case "NO_NAVBAR":
      default:
        setShowTopNavbar(false);
        break;
    }
  }, [user]);

  return (
    <TopNavbarContext.Provider
      value={{
        showTopNavbar,
        setShowTopNavbar,
        topNavbarContent,
        setTopNavbarContent,
      }}
    >
      {children}
    </TopNavbarContext.Provider>
  );
};
