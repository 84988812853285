import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { flushSync } from "react-dom";

export const LoadingButton = ({ loading, children, ...props }) => {
  const buttonRef = React.useRef(null);
  const [width, setWidth] = useState(null);

  useEffect(() => {
    let resizeObserver = null;
    let intersectionObserver = null;

    const updateWidth = () => {
      const widthPropWithPercentage =
        String(props.sx?.width ?? "").includes("%") ||
        String(props.sx?.width?.xs ?? "").includes("%") ||
        String(props.sx?.width?.sm ?? "").includes("%") ||
        String(props.sx?.width?.md ?? "").includes("%") ||
        String(props.sx?.width?.lg ?? "").includes("%");

      if (buttonRef.current && !widthPropWithPercentage) {
        setWidth(buttonRef.current.offsetWidth);
      }
    };

    function handleResize() {
      flushSync(() => {
        setWidth(null);
      })
      updateWidth();
    };
    if (buttonRef.current) {
      window.addEventListener('resize', handleResize);

      intersectionObserver = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          updateWidth();
        }
      });
      intersectionObserver.observe(buttonRef.current);
    }

    return () => {
      if (buttonRef.current) {
        window.removeEventListener('resize', handleResize);
      }
      if (intersectionObserver && buttonRef.current) {
        intersectionObserver.unobserve(buttonRef.current);
        intersectionObserver.disconnect();
      }
    };
  }, [props.sx]);

  const circularProgressSize = {
    grown: 24,
    shrinked: 24,
    large: 23,
    small: 10,
    micro: 8,
    default: 24,
  }[props.size || "default"];

  return (
    <Button
      ref={buttonRef}
      disabled={loading}
      {...props}
      sx={{ ...props.sx, ...(width ? { width } : {}) }}
    >
      {loading ? (
        <CircularProgress size={circularProgressSize} color="inherit" />
      ) : (
        children
      )}
    </Button>
  );
};
