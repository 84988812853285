import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

const FAQAccordion = ({ faqData }) => {
  return (
    <>
      {faqData.map((item, index) => (
        <Accordion
          key={index}
          disableGutters
          sx={{
            width: "100%",
            boxShadow: "none",
            border: "none",
            "&::before": {
              display: "none",
            },
            "&:not(:last-child)": {
              marginBottom: 2,
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls={`faq-${index}-content`}
            id={`faq-${index}-header`}
            sx={{
              backgroundColor: "transparent",
              boxShadow: "none",
              padding: 0,
              "& .MuiAccordionSummary-content": {
                margin: 0,
              },
              "& .MuiAccordionSummary-expandIconWrapper": {
                color: "inherit",
              },
            }}
          >
            <Typography
              variant="h5"
              sx={{ mr: "auto", textAlign: "left", textWrap: "pretty" }}
            >
              {item.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="subtitle2"
              sx={{
                mr: "auto",
                textAlign: "left",
                mt: 1,
                textWrap: "pretty",
              }}
            >
              {item.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default FAQAccordion;
