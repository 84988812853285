import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  DialogActions,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { LoadingButton } from "../LoadingButton.js";
import { useMutation } from "@tanstack/react-query";

function NewGroupDialog({
  user,
  open,
  handleClose,
  handleCreateGroup,
  handleMessage,
}) {
  const [groupName, setGroupName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [handle, setHandle] = useState(user?.handle);
  const { mutateAsync: handleSubmit, isLoading } = useMutation(async () => {
    await handleCreateGroup(groupName, groupDescription, handle);
  });
  const handleInputRef = useRef();
  const groupNameInputRef = useRef();

  useEffect(() => {
    if (handleInputRef.current) {
      handleInputRef.current?.focus();
    } else {
      groupNameInputRef.current?.focus();
    }
  }, []);

  useEffect(() => {
    setGroupName("")
    setGroupDescription("")
  }, [open])

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Let's set up your group</DialogTitle>
      <DialogContent>
        <form
          data-testid="form-create-group"
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          {!user?.handle && (
            <TextField
              inputRef={handleInputRef}
              required
              disabled={
                user &&
                user.handle !== null &&
                user.handle !== undefined &&
                user.handle !== ""
              }
              value={handle}
              label="Username"
              helperText={
                handleMessage
                  ? handleMessage
                  : `You can use letters, numbers, dashes (-), and underscores (_). For example, the Knicks use "nba-knicks".`
              }
              variant="standard"
              onChange={(event) => {
                let value = event.target.value;
                value = value.replace(/[A-Z]/g, (match) => match.toLowerCase());
                value = value.replace(/[^a-z0-9\-\_]/g, "");
                setHandle(value);
              }}
              error={!!handleMessage}
              sx={{ width: "100%", mb: 1 }}
            />
          )}
          <TextField
            inputRef={groupNameInputRef}
            data-testid="input-group-name"
            required
            autoFocus
            value={groupName}
            label="Give your group a name"
            fullWidth
            variant="standard"
            onChange={(event) => setGroupName(event.target.value)}
          />
          <TextField
            data-testid="input-group-description"
            autoFocus
            value={groupDescription}
            label="A few words to describe your group"
            fullWidth
            variant="standard"
            sx={{ mt: 2 }}
            onChange={(event) => setGroupDescription(event.target.value)}
          />
          <LoadingButton
            variant="contained"
            type="submit"
            size="large"
            loading={isLoading}
            sx={{ float: "right", mt: 3 }}
          >
            Save
          </LoadingButton>
          <Button
            onClick={handleClose}
            size="large"
            sx={{ float: "right", mt: 3, mr: 1 }}
          >
            Cancel
          </Button>
        </form>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}

export default NewGroupDialog;
