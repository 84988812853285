import axios from "axios";
import { useState } from "react";
import { getApiRoute } from "src/services";
import { useMutation } from "@tanstack/react-query";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
import { Link } from "@mui/material";

export function useForceRefreshGoogleCalendar({ refetchGoogleCalendarStatus = null }) {
    const [forceRefreshCalendarId, setForceRefreshCalendarId] = useState(null)
    const {
        setSnackbarOpen,
        setSnackbarMessage,
        setSnackbarSeverity,
        showSnackBar,
    } = useSnackbarContext();

    async function forceRefreshGoogleCalendar({ type, calendarId, handle }) {
        setForceRefreshCalendarId(calendarId)
        await axios.post(
            getApiRoute("library", "FORCE_REFRESH_GOOGLE_CALENDAR", { type, calendarId, handle }),
            {},
            { withCredentials: true }
        );
    }

    const mutation = useMutation(forceRefreshGoogleCalendar)

    const handleForceRefreshGoogleCalendar = async (calendar) => {
        const isGroup = Boolean(calendar?.calendars)
        try {
            await mutation.mutateAsync({ type: isGroup ? "group" : "calendar", calendarId: calendar._id, handle: calendar.handle })
            refetchGoogleCalendarStatus?.();
            showSnackBar(
                setSnackbarOpen,
                setSnackbarMessage,
                setSnackbarSeverity,
                `${isGroup ? "Group" : "Calendar"} refreshed successfully.`,
                "success"
            );
        } catch (error) {
            showSnackBar(
                setSnackbarOpen,
                setSnackbarMessage,
                setSnackbarSeverity,
                <>An error occurred refreshing the {isGroup ? "group" : "calendar"}. Please <Link sx={{ cursor: "pointer" }} onClick={() => handleForceRefreshGoogleCalendar(calendar)}>try again</Link>. If you're still facing issues, you've hit Google Calendar's API rate limits and need to wait a few minutes for it to reset. <Link sx={{ cursor: "pointer" }} onClick={() => window.open("https://stanza-1.gitbook.io/stanza-help-center/for-subscribers/stanzaplus/google-connect#im-seeing-an-error-message-that-ive-hit-google-calendars-api-limits.-what-does-this-mean-and-how-do", "_blank")}>Learn more.</Link></>,
                "error"
            );
        }
    }


    return { handleForceRefreshGoogleCalendar, forceRefreshCalendarId, ...mutation };
}
