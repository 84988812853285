import React, { useContext, useState } from "react";
import { Typography, Box, Button, Stack, Divider, TextField, Icon } from "@mui/material";
import { AuthContext } from "src/utils/AuthContext.js";
import Navbar from "../blocks/Navbar.js";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { getApiRoute } from "src/services";
import Footer from "../blocks/Footer.js";
import { useNavigate } from "react-router-dom";
import { ContainerCloseButton } from "./ContainerCloseButton.js";
import { useDimensions } from "src/utils/useDimensions.js";
import { useTheme } from "@emotion/react";

function CreatorMailingListUpgrade() {
  const { user, refetchAuth } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const [privacyLink, setPrivacyLink] = useState(user.mailingList?.privacy || "");
  const [termsLink, setTermsLink] = useState(user.mailingList?.terms || "");
  const { isSmallScreen } = useDimensions();
  const theme = useTheme();

  // State hooks to track validation and error messages
  const [termsLinkError, setTermsLinkError] = useState(false);
  const [termsLinkErrorMessage, setTermsLinkErrorMessage] = useState('');

  const [privacyLinkError, setPrivacyLinkError] = useState(false);
  const [privacyLinkErrorMessage, setPrivacyLinkErrorMessage] = useState('');


  const navigate = useNavigate();

  const isUrlValid = (url) => {
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    return regex.test(url);
  }

  const handleUpgrade = async (event) => {
    event.preventDefault();

    // Validate terms link
    if (!isUrlValid(termsLink)) {
      setTermsLinkError(true);
      setTermsLinkErrorMessage("Invalid URL format");
      return;
    } else {
      setTermsLinkError(false);
      setTermsLinkErrorMessage("");
    }

    // Validate privacy link
    if (!isUrlValid(privacyLink)) {
      setPrivacyLinkError(true);
      setPrivacyLinkErrorMessage("Invalid URL format");
      return;
    } else {
      setPrivacyLinkError(false);
      setPrivacyLinkErrorMessage("");
    }

    let forward = searchParams.get("forward");
    let successUrl;
    if (forward) {
      successUrl = `${forward}`;
    } else {
      successUrl = `/${user.handle}`;
    }

    // Update user with terms and privacy policy
    try {
      await axios.post(
        getApiRoute("auth", "UPDATE"),
        { privacyPolicy: privacyLink, termsOfService: termsLink },
        { withCredentials: true }
      );
      const response = await axios.post(
        getApiRoute("upgrade", "STRIPE_CHECKOUT"),
        { type: "creator-mailing-list", successUrl: successUrl },
        { withCredentials: true }
      );
      refetchAuth();
      window.location.href = response.data.url;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Navbar user={user} />
      <Box
        id="container"
        sx={{ margin: "auto", maxWidth: { xs: '100%', sm: "600px" }, position: "relative", p: 2, textAlign: "center" }}
      >
        {isSmallScreen && <ContainerCloseButton />}
        <Icon baseClassName="material-symbols-outlined" sx={{ display: "block", margin: "0 auto", color: `${theme.palette.icon.primary}`, verticalAlign: "middle", fontSize: "3rem", mt: 5, mb: 1, fontWeight: 300 }}>
          contact_mail_outlined
        </Icon>
        <Typography variant="title" data-testid="creator-mailing-list-upgrade-title" >
          Grow your mailing list.
        </Typography>
        <Typography variant="subtitle2" sx={{ mb: 3, color: "text.secondary" }}>
          ... like the
          <Typography
            component="a"
            variant="body2"
            onClick={() => navigate("/nba-warriors")}
            sx={{ textDecoration: 'underline', cursor: 'pointer', ml: .5 }}
          >
            New York Knicks
          </Typography>
        </Typography>
        <Stack sx={{ margin: "auto", maxWidth: "300px", textAlign: "center", mt: 3 }}>

          <Box display="flex" flexDirection="column" mx="auto" gap={1} >
            <Typography variant="body1" sx={{ mb: 1, textAlign: "left" }}>
              <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "bottom", mr: "10px", fontWeight: 300 }}>
                patient_list
              </Icon>
              Collect name and email
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, textAlign: "left" }}>
              <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "bottom", mr: "10px", fontWeight: 200 }}>
                data_table
              </Icon>
              Contacts available via spreadsheet
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, textAlign: "left" }}>
              <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "bottom", mr: "10px", fontWeight: 300 }}>
                done_all
              </Icon>
              GDPR & CCPA compliant
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, textAlign: "left" }}>
              <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "bottom", mr: "10px", fontWeight: 300 }}>
                integration_instructions
              </Icon>
              CRM integration coming soon
            </Typography>
          </Box>


          <Divider sx={{ color: "text.secondary", mt: 3, mb: 3 }} />
          <Typography variant="subtitle1" sx={{ mb: 2, textAlign: "center" }}>
            Subscribers must agree to your policies:
          </Typography>

          <form onSubmit={handleUpgrade}>
            <Stack spacing={3}>
              <TextField
                required
                value={termsLink}
                data-testid="creator-terms-of-service"
                multiline
                label="Terms of Service"
                helperText={termsLinkErrorMessage || "e.g. https://yourwebsite.com/terms"}
                variant="standard"
                error={termsLinkError}
                onChange={(event) => setTermsLink(event.target.value)}
              />
              <TextField
                required
                value={privacyLink}
                data-testid="creator-privacy-policy"
                multiline
                label="Privacy Policy"
                helperText={privacyLinkErrorMessage || "e.g. https://yourwebsite.com/privacy"}
                sx={{ mt: 2, mb: 3 }}
                variant="standard"
                error={privacyLinkError}
                onChange={(event) => setPrivacyLink(event.target.value)}
              />
            </Stack>
            <Button
              data-testid="creator-mailing-list-upgrade-button"
              size="shrinked"
              variant="contained"
              color="primary"
              type="submit"
              sx={{ mt: 4, width: "100%" }}
            >
              Next
            </Button>

          </form>
          <Typography
            variant="footer"
            sx={{ mt: 3, mb: 1, textAlign: "center", fontSize: "11px", lineHeight: "14.85px" }}
          >
            Your plan starts as soon as you set up payment. We'll renew your plan for you (unless you cancel 24 hours before the end of your billing cycle). You can manage your subscription under Your Plan.
          </Typography>
        </Stack>
      </Box >
      <Footer />
    </div >
  );
}

export default CreatorMailingListUpgrade;