import { useEffect, useContext } from "react";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
import { getPageRoute } from "src/services";
import {
  Icon,
  Typography,
  Button,
} from "@mui/material";
import { AuthContext } from "src/utils/AuthContext.js";
import InstructionsLayout from "../InstructionsLayout.js";
import { Link } from "@mui/material";
import FAQAccordion from "../FAQAccordian.js";

const GoogleCalendarConnected = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const theme = useTheme();

  const {
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarSeverity,
    showSnackBar,
  } = useSnackbarContext();

  useEffect(() => {
    if (!user) return

    if (!user?.hasValidSubscription) {
      navigate(getPageRoute("home", "HOME"));
      setTimeout(() => {
        showSnackBar(
          setSnackbarOpen,
          setSnackbarMessage,
          setSnackbarSeverity,
          "This feature is only for Stanza+ members.",
          "warning"
        );
      }, 1000);
    }
  }, [!!user]);

  const faqData = [
    {
      question: "How long will it take it to migrate my existing calendars & groups?",
      answer:
        "It takes anywhere from 10 - 30 seconds to sync one calendar with your Google Calendar. If you've subscribed to over 20 calendars, it may take a few minutes to complete the migration.",
    },
    {
      question: "Do I need to do anything on my other devices?",
      answer:
        `No, now that you've connected your Google Calendar account, all calendars and events will automatically appear on all devices where you're signed in with your Google account (${user.email})`,
    },
    {
      question: "Do I need to wait until the migration is complete to start adding new calendars?",
      answer: (
        'No, you can start adding new calendars right away. They will automatically sync with your Google Calendar account.'
      ),
    },
    {
      question: "Some of my calendars didn't migrate over. How should I fix this?",
      answer: (
        'You should re-sync by clicking on the three dots menu beside any calendar or group on your Library page, and selecting "Force Refresh".'
      ),
    },
  ];

  return (
    <>
      <InstructionsLayout>
        <Icon
          baseClassName="material-symbols-outlined"
          sx={{
            display: "block",
            margin: "0 auto",
            color: `${theme.palette.icon.primary}`,
            verticalAlign: "middle",
            fontSize: "3rem",
            mb: 1,
            fontWeight: 300,
          }}
        >
          sync
        </Icon>
        <Typography variant={"h2"}>We're on it!</Typography>
        <Typography variant={"subtitle2"} sx={{ mt: 4 }} color="text.primary">
          All your existing calendars & groups are being migrated to your Google Calendar. Head to your Library to see status updates.
        </Typography>
        <Button
          variant="contained"
          size="grown"
          sx={{ mt: 2, width: "100%" }}
          onClick={() => navigate(getPageRoute("library", "LIBRARY"))}
        >
          GO TO LIBRARY
        </Button>
        <Typography variant={"h2"} sx={{ mt: 5, mb: 2 }}>
          FAQs
        </Typography>
        <FAQAccordion faqData={faqData} />
        <Button
          size="grown"
          variant="outlined"
          onClick={() => window.open("https://stanza-1.gitbook.io/stanza-help-center/for-subscribers/stanzaplus/google-connect")}
          sx={{ mt: 3, mb: 10, height: "auto", width: "100%" }}
        >
          See all FAQs
        </Button>
      </InstructionsLayout>
    </>
  );
};

export default GoogleCalendarConnected;
