import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.js";
import reportWebVitals from "./reportWebVitals.js";
import { AuthProvider } from "./utils/AuthContext.js";
import { ThemeProvider } from "@mui/material";
import { Theme } from "./styles/Theme.js";
import { SnackbarProvider, Snackbar } from "src/utils/SnackbarContext.js";
import { ErrorBoundary, ErrorComponent } from "./utils/ErrorBoundary/index.js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const root = ReactDOM.createRoot(document.getElementById("root"));
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <ErrorBoundary fallback={<ErrorComponent />}>
        <Elements stripe={stripePromise}>
          <SnackbarProvider>
            <AuthProvider>
              <Snackbar />
              <App />
            </AuthProvider>
          </SnackbarProvider>
        </Elements>
      </ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
