import axios from "axios";
import { getApiRoute } from "src/services";
import { useMutation } from "@tanstack/react-query";

async function addToGroup({ groupId, calendarIds}) {
  await axios.post(
    getApiRoute("user", "ADD_CALENDARS_TO_GROUP"),
    { groupId, calendarIds },
    { withCredentials: true }
  );
}

export function useAddToGroup() {
  return useMutation(addToGroup);
}