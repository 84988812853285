import { useEffect, useContext } from "react";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { getPageRoute } from "src/services";
import { customEvent } from "src/utils/gtag.js";
import {
  Icon,
  Typography,
  Button,
} from "@mui/material";
import { AuthContext } from "src/utils/AuthContext.js";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
import InstructionsLayout from "../InstructionsLayout.js";

const ConnectGoogleCalendar = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const theme = useTheme();

  const {
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarSeverity,
    showSnackBar,
  } = useSnackbarContext();

  useEffect(() => {
    if (!user) return

    if (!user?.hasValidSubscription) {
      navigate(getPageRoute("home", "HOME"));
      setTimeout(() => {
        showSnackBar(
          setSnackbarOpen,
          setSnackbarMessage,
          setSnackbarSeverity,
          "This feature is only for Stanza+ members.",
          "warning"
        );
      }, 1000);
    }

    if (user.hasValidGoogleConnection) {
      navigate(getPageRoute("library", "LIBRARY"));
      setTimeout(() => {
        showSnackBar(
          setSnackbarOpen,
          setSnackbarMessage,
          setSnackbarSeverity,
          "You've already connected your Google Calendar. We're syncing all your calendars & groups.",
          "success"
        );
      }, 1000);
    }
  }, [!!user]);

  const handleConnectGoogleCalendar = async () => {
    customEvent({
      name: "connect_google_calendar",
      category: "GoogleCalendarExperience",
      label: "Connect Google Calendar Click",
      data: {
        user_id: user._id,
        user_email: user.email,
        user_handle: user.handle ?? "no_handle",
      },
    });
    const clientId = process.env.REACT_APP_OAUTH2_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_OAUTH2_REDIRECT_URI;
    const scope = encodeURIComponent(
      "https://www.googleapis.com/auth/calendar"
    );
    const responseType = "code";
    const accessType = "offline"; // Necessary to receive a refresh token
    const prompt = "consent"; // Ensure consent screen is always shown to get refresh token

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&access_type=${accessType}&prompt=${prompt}`;

    // Redirect the user to Google's OAuth 2.0 consent screen
    window.location.href = authUrl;
  };

  return (
    <>
      <InstructionsLayout>
        <Icon
          baseClassName="material-symbols-outlined"
          sx={{
            display: "block",
            margin: "0 auto",
            color: `${theme.palette.icon.primary}`,
            verticalAlign: "middle",
            fontSize: "3rem",
            mb: 1,
            fontWeight: 300,
          }}
        >
          rocket_launch
        </Icon>
        <Typography variant={"h2"}>Let's get started!</Typography>
        <Typography variant={"subtitle2"} sx={{ mt: 4 }} color="text.secondary">
          Next, you'll authorize Stanza to connect with your Google Calendar and make updates in the background.
        </Typography>
        <Typography variant={"subtitle2"} sx={{ mt: 2 }} color="text.secondary">
          We <u>only</u> access calendars added via Stanza.
        </Typography>
        <Button
          variant="contained"
          size="grown"
          sx={{ mt: 2, width: "330px" }}
          onClick={handleConnectGoogleCalendar}
        >
          CONNECT GOOGLE CALENDAR
        </Button>
        <Typography variant={"body1"} sx={{ mt: 2 }} color="text.primary">
          Make sure to sign in with
          <br />
          <span style={{ fontWeight: 700, marginTop: "4px" }}>
            {user.email}
          </span>
        </Typography>
      </InstructionsLayout>
    </>
  );
};

export default ConnectGoogleCalendar;
