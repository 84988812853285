import axios from "axios";
import { getApiRoute } from "src/services";
import { useMutation } from "@tanstack/react-query";

async function removeFromGroup({ calendarId }) {
  await axios.post(
    getApiRoute("user", "REMOVE_CALENDARS_FROM_GROUP"),
    { calendarId },
    { withCredentials: true }
  );
}

export function useRemoveFromGroup() {
  return useMutation(removeFromGroup);
}