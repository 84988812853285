import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { useTheme } from "@emotion/react";
import PermContact from "src/assets/svg/permcontact.js";
import { Box, Typography, Button, Divider } from "@mui/material";
import { AuthContext } from "src/utils/AuthContext.js";
import { useMutation } from "@tanstack/react-query";
import { generateGCalReminder } from "src/utils/Utils.js";
import { getPageRoute } from "src/services";
import { customEvent } from "src/utils/gtag.js";
import ConfirmationScreen from "../ConfirmationScreen.js";
import UAParser from "ua-parser-js";
import InstructionsLayout from "../InstructionsLayout.js";

const GoogleCalendarExperienceMobile = () => {
  const [step, setStep] = useState("MainSection");

  const location = useLocation();
  const calendar = location.state ? location.state.calendar ?? null : null;
  const theme = useTheme();
  const navigate = useNavigate();
  const uaParser = new UAParser();
  const parserResults = uaParser.getResult();

  const isDesktop = !parserResults.device.type;

  useEffect(() => {
    if (isDesktop || !calendar) {
      navigate(getPageRoute("home", "HOME"));
    }
  }, [isDesktop, calendar]);

  return (
    <InstructionsLayout
      sx={{
        alignItems: step !== "subscribeOnDesktop" ? "center" : "start",
        textAlign: step !== "subscribeOnDesktop" ? "center" : "start",
      }}
      nav="home"
    >
      {
        {
          MainSection: <MainSection setStep={setStep} calendar={calendar} />,
          downloadReminder: (
            <DownloadReminder calendar={calendar} setStep={setStep} />
          ),
        }[step]
      }
    </InstructionsLayout>
  );
};

const MainSection = ({ setStep }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const user = useContext(AuthContext).user;

  const handleDownloadReminder = () => {
    setStep("downloadReminder");
  };

  return (
    <>
      <PermContact />
      <Typography variant={"h2"} sx={{ mt: 1.5 }}>
        Google Calendar App doesn't support new calendar subscriptions
      </Typography>
      <Typography variant={"subtitle2"} sx={{ mt: 1.5 }} color="text.secondary">
        You can show or hide existing calendars but you can only subscribe to
        new calendars on the web.
      </Typography>
      <Divider sx={{ my: 2.5 }}> </Divider>
      <Typography variant={"subtitle1"} color="text.secondary">
        Here are your options:
      </Typography>
      <Button
        variant="contained"
        size="grown"
        sx={{ mt: 2, width: "330px" }}
        onClick={() => {
          customEvent({
            name: "nav_google_calendar_steps_experience_page",
            category: "GoogleCalendarExperience",
            label: "Navigate to Google Calendar Steps from Experience Page",
            data: {
              user_id: user._id,
              user_email: user.email,
              user_handle: user.handle ?? "no_handle",
            },
          });
          navigate(getPageRoute("help", "SUBSCRIBING_GOOGLE_CALENDAR_STEPS"));
        }}
      >
        CONNECT GOOGLE CALENDAR
      </Button>
      <Typography
        variant={"subtitle2"}
        sx={{
          mt: 2,
          color: `${theme.palette.text.secondary} !important`,
          width: "330px",
        }}
      >
        We'll keep your Google Calendar updated across all devices and push
        updates in <u>near real-time</u>.
      </Typography>
      <Box sx={{ width: "100%", maxWidth: "330px" }}>
        <Divider sx={{ mt: 3, mb: 3.5 }}>
          <Typography variant="subtitle2" color="text.secondary">
            or
          </Typography>
        </Divider>
      </Box>

      <Button
        variant="outlined"
        size="grown"
        sx={{ width: "330px" }}
        onClick={handleDownloadReminder}
      >
        SUBSCRIBE ON DESKTOP & SYNC
      </Button>
      <Typography
        variant={"subtitle2"}
        sx={{
          mt: 2,
          color: `${theme.palette.text.secondary} !important`,
          width: "330px",
        }}
      >
        You'll need to manually sync across every device.
        <br /> Updates can take up to <u>24-48 hours</u> to appear.
      </Typography>
    </>
  );
};

const DownloadReminder = ({ calendar, setStep }) => {
  const theme = useTheme();
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleDownloadGCalReminder = async () => {
    customEvent({
      name: "download_gcal_reminder",
      category: "GoogleCalendarExperience",
      label: "Download Google Calendar Reminder Click",
      data: {
        user_id: user._id,
        user_email: user.email,
        user_handle: user.handle ?? "no_handle",
      },
    });

    const stanzaLink = `${calendar?.stanzaLink}/${user?._id}.ics`;
    const icsContent = generateGCalReminder(calendar?.name, stanzaLink);
    const blob = new Blob([icsContent], {
      type: "text/calendar;charset=utf-8",
    });
    const url = URL.createObjectURL(blob);

    // Create a temporary link to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.download = `Sync_${calendar.name}_GCal_Reminder.ics`;

    // Append to the document and trigger the click
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    return true;
  };

  const {
    mutateAsync: downloadGCalReminder,
    data: isDone,
    isLoading,
  } = useMutation(handleDownloadGCalReminder);

  useEffect(() => {
    if (isDone) {
      navigate(getPageRoute("home", "HOME"), {
        state: {
          recommendedCalendars: {
            calendar,
            displayMode: "downloadGCalReminderConfirmation",
          },
        },
      });
    }
  }, [isDone]);

  return (
    <ConfirmationScreen
      title="Download a reminder"
      subcopy="When you're next on your desktop, open the calendar reminder with instructions on how to finish subscribing."
      buttonText="CONTINUE DOWNLOADING"
      buttonClickHandler={downloadGCalReminder}
      loading={isLoading}
      backClickHandler={() => setStep("MainSection")}
    />
  );
};

export default GoogleCalendarExperienceMobile;
