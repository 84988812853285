import StepsScreen from "src/components/instructions/StepsScreen.js";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { AuthContext } from "src/utils/AuthContext.js";
import { LoadingButton } from "../../blocks/LoadingButton.js";
import { Typography, Box, Skeleton } from "@mui/material";
import { getPageRoute } from "src/services";
import { UpgradeConsumerButton } from "../../upgrades/UpgradeConsumerButton.js";
import MuxPlayer from "@mux/mux-player-react";

const GoogleCalendarSteps = () => {
  const steps = [
    {
      title: "Connect your Google Calendar",
      description:
        "Stanza will then be able to add calendars and events to your Google Calendar in the background.",
      icon: "link",
    },
    {
      title: "Unlock subscribing directly on mobile",
      description:
        "Stanza's background updates bypass Google's limitations, adding calendars across all your devices.",
      icon: "smartphone",
    },
    {
      title: "Take control of your updates",
      description:
        "Get faster calendar updates, and know exactly when your calendar last updated. Force refresh if needed.",
      icon: "update",
    },
  ];

  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  return (
    <StepsScreen title="How it works" steps={steps}>
      <Typography variant={"h2"} sx={{ textAlign: "left", mr: "auto" }}>
        This is a new Stanza+ feature
      </Typography>

      {user && !user.hasValidSubscription ? (
        <>
          <Typography
            variant={"subtitle2"}
            sx={{ textAlign: "left", mr: "auto", mt: 1 }}
            color="text.secondary"
          >
            Save time and unlock unlimited calendar subscriptions.
          </Typography>
          <UpgradeConsumerButton
            size="grown"
            variant="contained"
            color="primary"
            sx={{ mt: 2, height: "68px", width: "100%" }}
            successUrl={getPageRoute("help", "CONNECT_GOOGLE_CALENDAR")}
          >
            <div>
              TRY FREE FOR 1 WEEK
              <br />
              <span style={{ textTransform: "capitalize" }}>Pay $0 today</span>
            </div>
          </UpgradeConsumerButton>
          <Typography
            variant="subtitle2"
            sx={{
              pt: 1.5,
              margin: "auto",
              color: "text.secondary",
              mb: 4,
              width: "90%",
            }}
          >
            Cancel any time before trial ends. After trial, Stanza+ is $2.50/mo
            (billed annually).
          </Typography>
          <Box sx={{ mb: { md: 5 }} } {...{
              minWidth: "100%", maxWidth: "100%"
          }}>
              {!isVideoLoaded && (
                  <Skeleton data-testid="video-loading" variant="rectangular" sx={{ width: "100%", height: "100%", aspectRatio: "16/9" }} />
              )}
              <MuxPlayer
                  streamType="on-demand"
                  data-testid="video-loaded"
                  playbackId="23JcSvw69CgjK6tqxOeCxqrUX015B7qtDNb4gVNeK3hY"
                  metadataVideoTitle="How Google Connect works with a Free Trial"
                  metadataViewerUserId={user?.email ?? "anonymous"}
                  accent-color="#000000"
                  style={{
                      display: isVideoLoaded ? "block" : "none",
                      aspectRatio: "16/9",
                  }}
                  onCanPlay={() => setIsVideoLoaded(true)}
                  onLoadedMetadata={() => setIsVideoLoaded(true)}
              />
              <Typography variant={"subtitle2"} sx={{ mt: 2 }}>Sound on! 🔊</Typography>
          </Box>
        </>
      ) : (
        <>
          <Typography
            variant={"subtitle2"}
            sx={{ textAlign: "left", mr: "auto", mt: 1 }}
            color="text.secondary"
          >
            Members save time and are the first to know.
          </Typography>
          <LoadingButton
            size="grown"
            variant="contained"
            color="primary"
            sx={{ mt: 2, height: "auto", width: "100%" }}
            onClick={() =>
              navigate(getPageRoute("help", "CONNECT_GOOGLE_CALENDAR"))
            }
          >
            Get Started
          </LoadingButton>
        </>
      )}
    </StepsScreen>
  );
};

export default GoogleCalendarSteps;
