import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  Divider,
} from "@mui/material";
import { MoreVertOutlined } from "@mui/icons-material";
import { useState } from "react";
import { timeAgo } from "src/utils/Utils.js";

const ThreeDotsMenu = ({ threeDotsMenu, calendar, googleCalendarStatusId }) => {
  const [threedotsOpen, setThreedotsOpen] = useState(null);
  const handleOpenThreeDotsMenu = (event) => {
    setThreedotsOpen(event.currentTarget);
  };
  const handleCloseThreeDotsMenu = () => {
    setThreedotsOpen(null);
  };

  return (
    <>
      <IconButton
        data-testid={`threedots-${calendar.name}`}
        onClick={handleOpenThreeDotsMenu}
      >
        <MoreVertOutlined />
      </IconButton>
      <Menu
        sx={{ mt: "32px", ".MuiMenu-paper": { minWidth: "135px" } }}
        anchorEl={threedotsOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(threedotsOpen)}
        onClose={handleCloseThreeDotsMenu}
      >
        {googleCalendarStatusId && [
          <Typography
            key="last-updated"
            variant="subtitle2"
            color="text.secondary"
            textAlign="center"
            sx={{ px: 2, py: 0.5 }}
          >
            Last updated: {timeAgo(googleCalendarStatusId.lastUpdated)}
          </Typography>,
          <Divider key="divider" component="li" sx={{ my: "6px" }} />,
        ]}
        {threeDotsMenu.map((menu, index) => (
          <MenuItem
            key={index}
            data-testid={`${menu.name}-button-${calendar.name}`}
            onClick={() => {
              menu.action(calendar);
              handleCloseThreeDotsMenu();
            }}
            disabled={menu.isDisabled?.(calendar)}
          >
            <ListItemIcon>
              {menu.icon({
                ...{ ...(menu.color ? { color: menu.color } : {}) },
              })}
            </ListItemIcon>
            <Typography
              variant="h5"
              textAlign="center"
              {...(menu.color ? { color: menu.color } : {})}
            >
              {menu.name}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ThreeDotsMenu;
