import { useContext, useCallback } from "react";
import { Box, Typography, Icon, IconButton, Tooltip, Link } from "@mui/material";
import { ContactSupportOutlined } from "@mui/icons-material";
import { AuthContext } from "src/utils/AuthContext.js";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
import { useCheckSubscriptionStatus, copyCalendarLinkToClipboard } from 'src/utils/Utils.js';
import { useTheme } from "@emotion/react";

const CalendarAddedSuccessfully = ({ displayMode, sourceCalendar, sx }) => {
    const { setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, showSnackBar } = useSnackbarContext();
    const { user, refetchAuth } = useContext(AuthContext);
    const { inGroup, subscribed } = useCheckSubscriptionStatus({ user, calendar: sourceCalendar });
    const copyToClipboard = useCallback(() => {
        copyCalendarLinkToClipboard({ inGroup, user, calendar: sourceCalendar, showSnackBar, setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity })
    }, [inGroup, sourceCalendar, setSnackbarMessage, setSnackbarOpen, setSnackbarSeverity, showSnackBar, user]);
    const theme = useTheme();

    const title = {
        "added": "You should be redirected to your calendar app.",
        "addedViaGoogleConnection": "Your Google Calendar is being updated.",
        "downloadGCalReminderConfirmation": "Your reminder is now set.",
        "oneTimeDownloadConfirmation": "Your one-time download is complete.",
    }[displayMode];

    const description = {
        "addedViaGoogleConnection": `It may take a few minutes but upcoming events from "${sourceCalendar?.name}" will soon be added to your Google Calendar across all devices. Any future updates will also automatically reflect in your calendar.`,
        "downloadGCalReminderConfirmation": `Follow the instructions in the reminder to subscribe to "${sourceCalendar?.name}" on your desktop, then sync it with the Google Calendar app on your phone.`,
        "oneTimeDownloadConfirmation": "Note: These events will not auto-update in your calendar app. You'll need to resubscribe to see the latest events.",
    }[displayMode];

    const icon = {
        "added": "launch",
        "addedViaGoogleConnection": "autorenew",
        "downloadGCalReminderConfirmation": "launch",
        "oneTimeDownloadConfirmation": "launch"
    }[displayMode];

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            mt: 3,
            gap: 1,
            ...sx,
        }}
            data-testid="calendar-added-successfully"
        >
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    <Icon baseClassName="material-symbols-outlined" sx={{ fontWeight: "300", fontSize: "1.5rem" }}>{icon}</Icon>
                    <Typography variant="h2">{title}</Typography>
                </Box>
                <Tooltip title="Support">
                    <IconButton
                        component="a"
                        href={`https://stanza-1.gitbook.io/stanza-help-center/for-subscribers/editor`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <ContactSupportOutlined fontSize="small" sx={{ color: `${theme.palette.text.secondary}` }} />
                    </IconButton>
                </Tooltip>
                {/*  <ShareDialog calendar={lastCalendarAdded} handle={lastCalendarAdded.handle} as="Button-Small" /> */}
            </Box>
            {displayMode === "added" ? (
                <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                    If you weren't redirected to your calendar app,
                    {' '}<Link
                        onClick={copyToClipboard}
                        sx={{ cursor: 'pointer', color: "text.secondary" }}
                    >
                        copy this link
                    </Link>{' '}
                    and follow these
                    {' '}<Link
                        href="https://stanza-1.gitbook.io/stanza-help-center/for-subscribers/editor/issues-redirecting-to-your-calendar-app/manually-import-a-calendar"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ cursor: 'pointer', color: "text.secondary" }}
                    >
                        instructions
                    </Link>{' '}
                    to import {sourceCalendar.name} into your calendar.
                </Typography>
            ) : (
                <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
                    {description}
                </Typography>
            )}
        </Box >
    )
}

export default CalendarAddedSuccessfully;