import * as React from "react";
const SVGComponent = ({
  width = 32,
  height = 32,
  status = "syncing",
  ...props
}) => {
  return (
    <div
      style={{
        width: "fit-content",
        display: "flex",
        position: "relative",
        padding: "0px 8px",
      }}
    >
      <style jsx>
        {`
          @keyframes spin {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        {...props}
      >
        <path
          d="M21.4166 10.3333C21.5166 10.9417 21.575 11.5667 21.575 12.225C21.575 15.2667 20.4916 17.8333 18.6083 19.575C16.9583 21.1 14.7 22 12 22C8.09164 22 4.71664 19.75 3.07497 16.4833C2.39164 15.1333 1.99997 13.6167 1.99997 12C1.99997 10.3833 2.39164 8.86667 3.07497 7.51667C4.71664 4.25 8.09164 2 12 2C14.7 2 16.9583 2.99167 18.6833 4.60833L15.8333 7.45834C14.7916 6.46667 13.475 5.95833 12 5.95833C9.39164 5.95833 7.1833 7.71667 6.39164 10.0917C6.19164 10.6917 6.07497 11.3333 6.07497 12C6.07497 12.6667 6.1833 13.3083 6.39164 13.9083C7.1833 16.2833 9.39164 18.0417 12 18.0417C13.35 18.0417 14.4916 17.675 15.3916 17.075C16.4416 16.3667 17.15 15.325 17.3916 14.0917H12V10.3333H21.4166Z"
          stroke="#000000"
          strokeLinejoin="round"
          strokeWidth="1.5px"
        />
      </svg>
      <svg
        focusable="false"
        aria-hidden="true"
        width={width * 0.6}
        height={height * 0.6}
        viewBox="0 0 24 24"
        style={{ position: "absolute", bottom: "-9%", left: "55%", zIndex: 1 }}
      >
        <circle cx="12" cy="12" r="11" fill="white" />
        {status == "success" && (
          <path
            d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
            fill="#008000"
          />
        )}
        {status == "syncing" && (
          <path d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z"
            style={{
              animation: "spin 2s linear infinite",
              transformOrigin: "center",
            }}
          />
        )}
        {status == "failed" && (
          <path
            d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            fill="#cd1c18"
          ></path>
        )}
      </svg>
    </div>
  );
};

export default SVGComponent;
