import React, { useContext } from "react";
import { Stack, IconButton, Icon, Tooltip, CircularProgress } from "@mui/material";
import { useTheme } from "@emotion/react";
import ShareDialog from "src/components/blocks/dialogs/ShareDialog.js";
import {
  handleOpenWebcal,
  getWebcalFormat,
  handleSubscribeExperience,
  getCalendarApiUrl,
} from "src/utils/Utils.js";
import { AuthContext } from "src/utils/AuthContext.js";
import { useNavigate } from "react-router-dom";
import { useForceRefreshGoogleCalendar } from "src/services/mutations/useForceRefreshGoogleCalendar.js";
import { useGoogleCalendarStatus } from "src/services/queries/useGoogleCalendarStatus.js";

const CreatorProfileButtons = ({ calendar, handle }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user, refetchAuth } = useContext(AuthContext);
  const { refetch: refetchGoogleCalendarStatus } = useGoogleCalendarStatus({ user });
  const { handleForceRefreshGoogleCalendar, isLoading: isLoadingForceRefreshGoogleCalendar } = useForceRefreshGoogleCalendar({ refetchGoogleCalendarStatus });

  const handleOpenPreview = () => {
    window.open(`/preview/${handle}/${calendar._id}`, "_blank");
  };

  return (
    <Stack direction="row" spacing={2}>
      <ShareDialog calendar={calendar} handle={handle} as="Button" />
      <Tooltip title="Preview">
        <IconButton onClick={handleOpenPreview} size="small">
          <Icon
            baseClassName="material-symbols-outlined"
            fontSize="medium"
            sx={{
              color: `${theme.palette.icon.primary}`,
              verticalAlign: "middle",
            }}
          >
            visibility
          </Icon>
        </IconButton>
      </Tooltip>
      {/* resubscribe to own created group */}
      {calendar.calendars && (
        <>
          {!user?.hasValidGoogleConnection ? (
            <Tooltip title="Resubscribe">
              <IconButton
                onClick={() => {
                  const groupApiUrl = getCalendarApiUrl({ calendar, user });
                  const defaultFn = () =>
                    handleOpenWebcal(
                      user,
                      getWebcalFormat(groupApiUrl),
                      calendar,
                      navigate
                    );
                  handleSubscribeExperience({
                    user,
                    navigate,
                    calendar,
                    defaultFn,
                  });
                }}
                size="small"
              >
                <Icon
                  baseClassName="material-symbols-outlined"
                  fontSize="small"
                  sx={{
                    color: `${theme.palette.icon.primary}`,
                    verticalAlign: "middle",
                  }}
                >
                  event_repeat
                </Icon>
              </IconButton>
            </Tooltip>
          ) :
            !isLoadingForceRefreshGoogleCalendar ? (
              <Tooltip title="Force Refresh">
                <IconButton
                  onClick={() => handleForceRefreshGoogleCalendar(calendar)}
                  size="small"
                  disabled={isLoadingForceRefreshGoogleCalendar}
                >
                  <Icon
                    baseClassName="material-symbols-outlined"
                    fontSize="small"
                    sx={{
                      color: `${theme.palette.icon.primary}`,
                      verticalAlign: "middle",
                    }}
                  >
                    refresh
                  </Icon>
                </IconButton>
              </Tooltip>
            ) : (
              <IconButton
                size="small"
                disabled={true}
              >
                <CircularProgress size={18} color="inherit" />
              </IconButton>
            )
          }
        </>
      )}
    </Stack>
  );
};

export default CreatorProfileButtons;
