import React, { forwardRef } from "react";
import { Box, Link } from "@mui/material";
import { getPageRoute } from "src/services";
import { Link as RouterLink } from "react-router-dom";

export const TopBanner = forwardRef((props, ref) => {
  return (
    <Box
      ref={ref}
      sx={{
        position: "fixed",
        zIndex: 5,
        top: 0,
        width: "100%",
        display: "flex-inline",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        py: 1,
        backgroundColor: "#000000",
        color: "#ffffff",
      }}
      typography={"subtitle1"}
      {...props}
    >
      {props.children}
    </Box>
  );
});
