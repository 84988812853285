import StepsScreen from "src/components/instructions/StepsScreen.js";
import { useNavigate } from "react-router-dom";
import { Link, Button } from "@mui/material";
import { getPageRoute } from "src/services";

const PaymentConfirmation = () => {
  const steps = [
    {
      title: "Add as many calendars as you like",
      description:
        "As a Stanza+ member, you've unlocked unlimited access to our library of calendars.",
      icon: "event_available",
    },
    {
      title: "Create groups to organize your calendars",
      description: (
        <>
          Group multiple calendars into one to reduce clutter in your calendar.{" "}
          <Link
            href="https://stanza-1.gitbook.io/stanza-help-center/for-subscribers/stanzaplus/groups"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ cursor: "pointer", color: "text.secondary" }}
          >
            Learn more.
          </Link>
        </>
      ),
      icon: "group",
    },
    {
      title: "Take control of your updates",
      description: (
        <>
          Stanza+ members get faster updates, across all their devices. Learn
          about{" "}
          <Link
            href="https://stanza-1.gitbook.io/stanza-help-center/for-subscribers/stanzaplus/google-connect"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ cursor: "pointer", color: "text.secondary" }}
          >
            Google Connect.
          </Link>{" "}
          and your other{" "}
          <Link
            href="https://stanza-1.gitbook.io/stanza-help-center/for-subscribers/stanzaplus/benefits"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ cursor: "pointer", color: "text.secondary" }}
          >
            benefits.
          </Link>
        </>
      ),
      icon: "update",
    },
  ];

  const navigate = useNavigate();

  return (
    <StepsScreen title="You're all set!" steps={steps} nav="home">
      <Button
        variant="contained"
        color="primary"
        sx={{ height: "auto", width: "100%" }}
        onClick={() => navigate(getPageRoute("home", "HOME"))}
      >
        Browse Library
      </Button>
    </StepsScreen>
  );
};

export default PaymentConfirmation;
