import axios from "axios";
import { getApiRoute } from "src/services";
import { useMutation } from "@tanstack/react-query";

async function unsubscribeGroup({ groupId }) {
    await axios.post(
        getApiRoute("user", "REMOVE_GROUP"),
        { groupId },
        { withCredentials: true }
      );
}

export function useUnsubscribeGroup() {
  return useMutation(unsubscribeGroup);
}