import React, { useState } from "react";
import { Typography, Button, Box } from "@mui/material";
import { LoadingButton } from "../blocks/LoadingButton.js";

const ConfirmationScreen = ({
  title,
  subcopy,
  buttonText,
  buttonClickHandler,
  loading,
  backClickHandler,
}) => {
  const [isLoading, setIsLoading] = useState(loading); // Manage loading state internally

  return (
    <Box sx={{ mt: "50%" }}>
      <Typography variant="h2">{title}</Typography>
      <Typography
        variant="subtitle2"
        color="text.secondary"
        sx={{ mt: 1.5, px: 1 }}
      >
        {subcopy}
      </Typography>
      <LoadingButton
        variant="contained"
        size="grown"
        sx={{ mt: 4, width: "280px" }}
        onClick={(event) => {
          event.preventDefault();
          buttonClickHandler();
        }}
        loading={isLoading} // Use internal isLoading state
      >
        {buttonText}
      </LoadingButton>
      <Button
        variant="text"
        size="grown"
        sx={{ width: "280px", mt: 2, color: "text.secondary" }}
        onClick={backClickHandler} // Call the passed back click handler
      >
        GO BACK
      </Button>
    </Box>
  );
};

export default ConfirmationScreen;
