import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getPageRoute } from "src/services/Config.js";
import Footer from "../blocks/Footer.js";

const InstructionsLayout = ({ children, nav = "back", sx }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Box
        id="container"
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "5% auto 0 auto",
          maxWidth: "400px",
          position: "relative",
          p: 2,
          px: 3.5,
          alignItems: "center",
          textAlign: "center",
          flex: 1,
          ...sx,
        }}
      >
        <Button
          variant="text"
          onClick={
            {
              back: () => navigate(-1),
              home: () => navigate(getPageRoute("home", "HOME")),
            }[nav]
          }
          sx={{ color: "text.secondary", mr: "auto", px: 0, mb: 3 }}
        >
          {{ back: "GO BACK", home: "GO HOME" }[nav]}
        </Button>

        {children}
      </Box>
      <Footer />
    </div>
  );
};

export default InstructionsLayout;
