import ical from "ical.js";
import axios from "axios";
import { getApiRoute } from "src/services";
import { getPageRoute } from "src/services";
import { useRef, useCallback, useEffect, useState, useMemo } from "react";
import Vibrant from "node-vibrant";
import temporaryEmailsSet from "./temporaryEmails.js";
import * as ICAL from "ical.js";
import { flushSync } from "react-dom";
import UAParser from "ua-parser-js";
import { customEvent } from "src/utils/gtag.js";
import dayjs from 'dayjs';

export const isEmptyCal = (events) =>
  events?.length === 1 &&
  events[0]?.jCal?.[1]
    ?.find((prop) => prop[0] === "description")?.[3]
    .includes(
      `has no upcoming events. Don't worry, as soon as new events are added - your calendar will be automatically updated.`
    );

// Helper function for getUpcomingEvents and getPastEvents
const getEventDates = (event, typeHomepage) => {
  const prop = typeHomepage ? event.event.jCal[1] : event.jCal[1];

  const getDtValue = (propName) => {
    const dateProp = prop.find((p) => p[0] === propName);
    if (!dateProp) return null;

    const dateValue = dateProp[3] || dateProp[0];
    const formattedDate =
      dateValue.split("T").length > 1
        ? dateValue
        : dateValue + "T00:00:00.000Z";

    return ical.Time.fromDateTimeString(formattedDate).toJSDate();
  };

  let dtstart = getDtValue("dtstart");
  let dtend = getDtValue("dtend");

  // In cases where events start time / end time is the same, the dtend property is missing. Default to dtstart.
  if (!dtend) {
    dtend = dtstart;
  }

  return { dtstart, dtend };
};

export const getUpcomingEvents = (events, count, typeHomepage) => {
  if (isEmptyCal(events)) {
    return [];
  }

  const now = new Date();
  const upcomingEvents = new Map();

  for (const event of events) {
    const { dtstart, dtend } = getEventDates(event, typeHomepage);
    if (dtend >= now) {
      upcomingEvents.set(event, { dtstart, dtend });
    }
  }

  const sortedEvents = Array.from(upcomingEvents.keys()).sort(
    (event1, event2) => {
      if (
        upcomingEvents.get(event2).dtstart < upcomingEvents.get(event1).dtstart
      ) {
        return 1;
      } else if (
        upcomingEvents.get(event2).dtstart > upcomingEvents.get(event1).dtstart
      ) {
        return -1;
      }
      return 0;
    }
  );

  return sortedEvents.slice(0, count); // Only include the first {count} upcoming events
};

export const getPastEvents = (events, count) => {
  if (isEmptyCal(events)) {
    return [];
  }

  const now = new Date();
  const pastEvents = new Map();

  for (const event of events) {
    const { dtstart, dtend } = getEventDates(event);
    if (dtend < now) {
      pastEvents.set(event, { dtstart, dtend });
    }
  }

  const sortedEvents = Array.from(pastEvents.keys()).sort((event1, event2) => {
    if (pastEvents.get(event2).dtstart > pastEvents.get(event1).dtstart) {
      return 1;
    } else if (
      pastEvents.get(event2).dtstart < pastEvents.get(event1).dtstart
    ) {
      return -1;
    }
    return 0;
  });

  return sortedEvents.slice(0, count); // Only include the first {count} past events
};

// Helper function to combine different webcals into one
export const fetchCalendarData = async (
  calendars,
  setEvents,
  setIsLoading = () => { }
) => {
  setIsLoading(true);
  try {
    const responses = await Promise.allSettled(
      calendars.map(async (calendar) => {
        const response = await axios.get(
          getApiRoute("calendar", "GET_CALENDAR_EVENTS", {
            link: encodeURIComponent(calendar.stanzaLink),
          })
        );
        return { response, calendar: calendar, calendarId: calendar._id };
      })
    );

    // filter out errors from public webcals that are not available
    const fulfilledResponses = responses.filter(
      (response) => response.status === "fulfilled"
    );
    const events = fulfilledResponses.flatMap((response) => {
      try {
        const jcalData = ical.parse(response.value.response.data);
        const comp = new ical.Component(jcalData);
        const subcomponents = comp.getAllSubcomponents("vevent");
        return subcomponents.map((subcomponent) => ({
          event: subcomponent,
          calendar: response.value.calendar,
          calendarId: response.value.calendarId,
        }));
      } catch (error) {
        console.error(error);
        return [];
      }
    });

    setEvents(events);
    setIsLoading(false);
  } catch (error) {
    console.error(error);
    setEvents([]);
    setIsLoading(false);
  }
};

// Convert the calendars icsFiles to iCal objects and get the events to merge them into one array
export const getEventsFromIcsFiles = async (calendars, setEvents) => {
  let events = [];
  for (const calendar of calendars) {
    try {
      if (!calendar.icsFile || typeof calendar.icsFile !== "string") {
        console.error(`Invalid icsFile for calendar ${calendar.name}`);
        continue;
      }

      const jcalData = ical.parse(calendar.icsFile);
      const comp = new ical.Component(jcalData);
      const subcomponents = comp.getAllSubcomponents("vevent");

      if (!isEmptyCal(subcomponents)) {
        events = [
          ...events,
          ...subcomponents.map((subcomponent) => ({
            event: subcomponent,
            calendar: { ...calendar },
            calendarId: calendar._id,
          })),
        ];
      }
    } catch (error) {
      console.error(`Error processing calendar ${calendar.name}:`, error);
    }
  }
  setEvents(events);
};

// Helper function to redirect a signed out user to the Sign In page
export const redirectSignedOutUser = (
  user,
  navigate,
  extraSearchParams = {}
) => {
  const newSearchParams = new URLSearchParams(window.location.search);
  for (const [key, value] of Object.entries(extraSearchParams)) {
    newSearchParams.set(key, value);
  }
  if (!user) {
    navigate(
      getPageRoute(
        "auth",
        "SIGNIN",
        {},
        {
          forward: window.location.pathname + "?" + newSearchParams.toString(),
        }
      )
    );
    return true; // indicate that a redirected happened
  }
  return false; // indicate that no redirect happened
};

// Helper function to load the Stripe customer portal
export const handleCustomerPortal = async () => {
  try {
    const response = await axios.post(
      getApiRoute("upgrade", "STRIPE_CUSTOMER_PORTAL"),
      {},
      { withCredentials: true }
    );
    window.location.href = response.data.url;
  } catch (error) {
    console.error(error);
  }
};

// Check if the calendar link is valid
export const validateCalendar = async (link) => {
  try {
    const response = await axios.get(
      getApiRoute("calendar", "GET_CALENDAR_EVENTS", {
        link: encodeURIComponent(link),
      }),
      { withCredentials: true }
    );
    if (response.status === 200) {
      console.log("It's a valid calendar link");
      return response;
    }
  } catch (error) {
    //return the error message
    return { error: "Webcal link is not valid or is not a public calendar" };
  }
};

// check if user has subscribed to the calendar
export const useCheckSubscriptionStatus = ({
  user = {},
  calendar = {},
} = {}) => {
  const [subscribed, setSubscribed] = useState(false);
  const {
    createdGroups = [],
    addedGroups = [],
    addedCalendars = [],
  } = user || {};
  const { id, _id } = calendar;

  // Memoize the Set to avoid recalculating on each render
  const createdGroupIds = useMemo(
    () => new Set(createdGroups.flatMap((group) => group.calendars)),
    [createdGroups]
  );

  // Determine subscription status
  const inGroup = createdGroupIds.has(_id ?? id);
  const addedGroup = addedGroups.includes(_id ?? id);
  const alreadyAddedCalendar = addedCalendars.includes(_id ?? id);

  useEffect(() => {
    setSubscribed(inGroup || addedGroup || alreadyAddedCalendar);
  }, [inGroup, addedGroup, alreadyAddedCalendar]);

  return {
    inGroup,
    addedGroup,
    subscribed,
    setSubscribed,
  };
};

// Helper function to format a number of subscribers. Set includeText = false if you only want the number
export const formatSubscribers = (subscribers, includeText = true) => {
  if (!subscribers || subscribers < 5) {
    return "";
  }
  if (subscribers === 1) {
    return includeText ? "1 Subscriber" : "1";
  }
  if (subscribers > 1000) {
    const roundedSubscribers = (subscribers / 1000).toFixed(1);
    const formattedSubscribers = roundedSubscribers.endsWith(".0")
      ? Math.round(roundedSubscribers)
      : roundedSubscribers;
    return includeText
      ? `${formattedSubscribers}K Subscribers`
      : `${formattedSubscribers}K`;
  }

  return includeText ? `${subscribers} Subscribers` : `${subscribers}`;
};

export const revealNavbar = (target = "window") => {
  const topBanner = document.getElementById("TopBanner");
  const offset = topBanner ? topBanner.clientHeight : 0;
  const navbar = document.getElementById("AppBar");
  const tabs = document.querySelector(".MuiTabs-root");
  if (navbar.style.opacity === "0") {
    if (target === "search") {
      navbar.style.opacity = "1";
      navbar.style.height = "56px";
      tabs.style.top = `${0 + offset}px`;
      return;
    }
    navbar.style.opacity = "1";
    tabs.style.top = `${48 + offset}px`;
  }
};

// Helper function to sort an array of objects alphabetically
export const sortAlphabetically = (arr, targetKey = null) => {
  return arr.sort((a, b) => {
    if (targetKey) {
      return a[targetKey].localeCompare(b[targetKey]);
    }
    return a.localeCompare(b);
  });
};

export function useIsMounted() {
  const mountedRef = useRef(false);
  const isMounted = useCallback(() => mountedRef.current, []);
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);
  return isMounted;
}

// Helper function to turn https:// link to webcal:// link
export function getWebcalFormat(url) {
  return `webcal://${url.replace(/^https?:\/\//, "")}`;
}

export const extractImageColors = async (imageUrl) => {
  const pallete = await Vibrant.from(imageUrl).getPalette();
  return {
    dark: pallete.DarkVibrant.getHex(),
    vibrant: pallete.Vibrant.getHex(),
    lightVibrant: pallete.LightVibrant.getHex(),
    lightMuted: pallete.LightMuted.getHex(),
    darkMuted: pallete.DarkMuted.getHex(),
  };
};

export const isInvalidEmail = (string) => {
  if (temporaryEmailsSet.has(string.split("@")[1])) {
    return true;
  }
  return (
    new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(string) === false ||
    string === ""
  );
};

export const parseDateEvent = (dateStr) => {
  let isAllDayEvent = false;
  let eventParsed = new Date(dateStr);
  // check if date is in format YYYY-MM-DD (full day event) and add timezone offset
  if (new RegExp(/^\d{4}-\d{2}-\d{2}$/).test(dateStr)) {
    isAllDayEvent = true;
    eventParsed = new Date(
      eventParsed.getTime() + eventParsed.getTimezoneOffset() * 60 * 1000
    );
  }
  return { eventParsed, isAllDayEvent };
};

// Format date to display in a more readable format
export const formatDate = (event, snackbarContext) => {
  const {
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarSeverity,
    showSnackBar,
  } = snackbarContext;
  try {
    if (!event) {
      return "";
    }
    let { eventParsed, isAllDayEvent } = parseDateEvent(event);
    const currentDate = new Date();
    const tomorrowDate = new Date(currentDate);
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);

    const eventDate = eventParsed.toLocaleDateString("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
    });
    const eventTime = eventParsed.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    if (isAllDayEvent) {
      return "All Day";
    } else if (eventParsed.toDateString() === currentDate.toDateString()) {
      return `Today at ${eventTime}`;
    } else if (eventParsed.toDateString() === tomorrowDate.toDateString()) {
      return `Tomorrow at ${eventTime}`;
    } else {
      return `${eventDate} | ${eventTime}`;
    }
  } catch (error) {
    console.error(error);
    showSnackBar(
      setSnackbarOpen,
      setSnackbarMessage,
      setSnackbarSeverity,
      "This browser isn't displaying dates correctly. Please use another browser.",
      "error"
    );
    return "Unknown date/time";
  }
};

// Create the .ics for the user to download an individual event
export const convertToIcs = (jCal) => {
  if (!jCal || !Array.isArray(jCal)) {
    console.error("Invalid jCal data");
    return "";
  }
  // Ensure the root component is VCALENDAR
  let comp;
  if (jCal[0] === "vcalendar") {
    // jCal represents a complete calendar object
    comp = new ICAL.Component(jCal);
  } else {
    // jCal represents an individual event (VEVENT), wrap it in a VCALENDAR component
    comp = new ICAL.Component(["vcalendar", [], []]);
    comp.addSubcomponent(new ICAL.Component(jCal));
  }
  // Convert the component to iCalendar format string
  const icsContent = comp.toString();
  return icsContent;
};

export const downloadIcsFileFromjCal = (jCal, eventSummary) => {
  // Logic for downloading the .ics file
  const icsContent = convertToIcs(jCal);
  const blob = new Blob([icsContent], { type: "text/calendar" });
  const url = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");
  link.href = url;

  // Set the download attribute with a filename
  const eventName = eventSummary ? eventSummary[3] : "event";
  link.download = `${eventName}.ics`;
  const keydownEvent = (e) => {
    if (e.key === "d") {
      const event = new MouseEvent("click", {});
      link.dispatchEvent(event);
    }
  };
  document.addEventListener("keydown", keydownEvent, { once: true });

  // Programmatically click the link to trigger the download by an event
  document.body.appendChild(link);
  const event = new KeyboardEvent("keydown", { key: "d" });
  document.dispatchEvent(event);

  // Remove the link from the document
  document.body.removeChild(link);

  // Release the object URL
  URL.revokeObjectURL(url);
};

export const fetchAndCacheImage = async (imageUrl, setImageUri) => {
  try {
    const response = await fetch(imageUrl, {
      cache: "force-cache",
    });
    if (response.ok) {
      const blob = await response.blob();
      const uri = URL.createObjectURL(blob);
      setImageUri(uri);
      return uri;
    }
  } catch (error) {
    console.error(error);
  }
};

export const copyCalendarLinkToClipboard = ({
  inGroup,
  user,
  calendar,
  showSnackBar,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarSeverity,
}) => {
  let calendarLink;
  if (inGroup) {
    calendarLink = getCalendarApiUrl({
      calendar: user.createdGroups.find((group) =>
        group.calendars.includes(calendar._id)
      ),
      user,
    });
  } else {
    calendarLink = getCalendarApiUrl({ calendar, user });
  }
  if (navigator.clipboard && "writeText" in navigator.clipboard) {
    navigator.clipboard
      .writeText(getWebcalFormat(calendarLink))
      .then(() => {
        showSnackBar(
          setSnackbarOpen,
          setSnackbarMessage,
          setSnackbarSeverity,
          "Calendar link copied successfully.",
          "success"
        );
        console.log("Link copied to clipboard!");
      })
      .catch((err) => {
        showSnackBar(
          setSnackbarOpen,
          setSnackbarMessage,
          setSnackbarSeverity,
          "There was an issue copying the link. Please try again.",
          "error"
        );
        console.error("Failed to copy: ", err);
      });
  } else {
    // Android browsers don't support clipboard API
    const input = document.createElement("input");
    input.value = getWebcalFormat(calendarLink);
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    showSnackBar(
      setSnackbarOpen,
      setSnackbarMessage,
      setSnackbarSeverity,
      "Calendar link copied successfully.",
      "success"
    );
  }
};

export const handleOpenWebcal = async (user, url, calendar, navigate) => {
  if (user.hasValidGoogleConnection) return
  const uaParser = new UAParser();
  const parserResults = uaParser.getResult();
  const isDesktop = !parserResults.device.type; // If undefined, it is a desktop device
  if (user.provider === "google" && isDesktop) {
    const link = document.createElement("a");
    const googleUrl = `https://calendar.google.com/calendar/render?cid=${encodeURIComponent(
      url
    )}`;
    link.href = googleUrl;
    link.target = "_blank";
    link.click();
  } else if (navigator.userAgent.match(/(iPod|iPhone|iPad|Safari|Mac)/)) {
    const link = document.createElement("a");
    link.href = url;
    link.download = `${calendar?.name}.ics`;
    if (
      navigator.userAgent.match(/Mac/) &&
      !navigator.userAgent.match(/(iPod|iPhone|iPad)/)
    ) {
      link.target = "_blank";
    }
    const keydownEvent = (e) => {
      if (e.key === "d") {
        const event = new MouseEvent("click", {});
        link.dispatchEvent(event);
      }
    };
    document.addEventListener("keydown", keydownEvent, { once: true });

    // Programmatically click the link to trigger the download by an event
    document.body.appendChild(link);
    const event = new KeyboardEvent("keydown", { key: "d" });
    document.dispatchEvent(event);

    // Remove the link from the document
    document.body.removeChild(link);
  } else {
    window.open(url, "_blank");
  }
};

export const handleUnsubscribe = async ({
  user,
  calendar,
  unsubscribeGroup,
  createdGroups,
  removeFromGroup,
  unsubscribeCalendar,
  onUnsubscribed = () => { },
  showSnackBar,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarSeverity,
  setSubscribed = () => { },
}) => {
  try {
    flushSync(() => {
      setSubscribed(false);
    });
    // Show success message
    showSnackBar(
      setSnackbarOpen,
      setSnackbarMessage,
      setSnackbarSeverity,
      "Calendar removed successfully.",
      "success"
    );
    // Determine the type of unsubscription and perform the appropriate action
    switch (true) {
      case user?.addedCalendars?.includes(calendar._id):
        await unsubscribeCalendar({ calendarId: calendar._id });
        break;
      case user?.addedGroups?.includes(calendar._id):
        await unsubscribeGroup({ groupId: calendar._id });
        break;
      case createdGroups?.some((group) =>
        group.calendars.some((calendarObj) => calendarObj._id === calendar._id)
      ):
        await removeFromGroup({ calendarId: calendar._id });
        break;
      default:
        throw new Error("Calendar or group not found in user's subscriptions.");
    }

    await onUnsubscribed();
  } catch (error) {
    setSubscribed(true);
    console.error(error);
    showSnackBar(
      setSnackbarOpen,
      setSnackbarMessage,
      setSnackbarSeverity,
      error.message || "An error occurred. Please try again.",
      "error"
    );
  }
};

export function getMinuteTimestamp() {
  const now = new Date();
  return Math.floor(now.getTime() / (1000 * 60));
}

export function timeAgo(date) {
  const now = dayjs();
  const diffMinutes = now.diff(date, 'minute');
  const diffHours = now.diff(date, 'hour');
  const diffDays = now.diff(date, 'day');

  if (diffMinutes < 1) {
    return `Just now`;
  } else if (diffMinutes < 60) {
    return `${diffMinutes} ${diffMinutes === 1 ? 'minute' : 'minutes'} ago`;
  } else if (diffHours < 24) {
    return `${diffHours} ${diffHours === 1 ? 'hour' : 'hours'} ago`;
  } else {
    return `${diffDays} ${diffDays === 1 ? 'day' : 'days'} ago`;
  }
}


// Utility function to format dates for ICS files
function formatDateForIcs(date) {
  return date.toISOString().replace(/[-:]/g, "").split(".")[0] + "Z";
}

// Function to generate the ICS file content
export function generateGCalReminder(calendarName, calendarLink) {
  const now = new Date();

  // Create a Date object for 1 hour from now
  const startDate = new Date(now.getTime() + 60 * 60 * 1000);
  const endDate = new Date(startDate.getTime() + 15 * 60 * 1000); // 15 minutes later

  const dtstart = formatDateForIcs(startDate);
  const dtend = formatDateForIcs(endDate);
  const dtstamp = formatDateForIcs(now);
  const uid = `reminder-${now.getTime()}@stanzacal.com`;

  // Instructions to include in the event description
  const description = `
Instructions for syncing with Google Calendar:

🖥️
1. Open Google Calendar on your desktop (https://calendar.google.com).
2. Click the "+" button next to "Other calendars" in the left panel.
3. Select "From URL" and paste this calendar URL: ${calendarLink}
4. Click "Add calendar".

📱
Now go to your Google Calendar app on your phone and find the new calendar.

1. Tap on ≡ on the top left. Scroll down and tap on the Settings menu item. This entry is almost at the end of the list.
2. Find ${calendarName} in the list. If you don't see the calendar in the list, scroll down to the bottom and tap on "Show more". 
3. Once you find the calendar, tap on it and toggle the "Sync"​​ button (first setting on top of the screen) to turn sync on.

✔ The calendar is now synced with your Google Calendar app. You should see the events in your calendar shortly.

You can delete this event once you're done syncing.
`.trim();

  // Generate the ICS file content
  const icsContent = `
BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Stanza//Calendar Reminder//EN
BEGIN:VEVENT
UID:${uid}
DTSTAMP:${dtstamp}
DTSTART:${dtstart}
DTEND:${dtend}
SUMMARY:🔔 Sync ${calendarName} with Google Calendar
DESCRIPTION:${description.replace(/\n/g, "\\n")}
END:VEVENT
END:VCALENDAR
`.trim();

  return icsContent;
}

export const handleSubscribeExperience = ({
  user,
  navigate,
  calendar,
  defaultFn,
  onSuccess = () => { },
}) => {
  const uaParser = new UAParser();
  const parserResults = uaParser.getResult();
  const isDesktop = !parserResults.device.type;
  const isAndroid = parserResults.os.name === "Android"

  if ((isAndroid || user?.provider === "google") && !user.hasValidGoogleConnection && !isDesktop) {
    navigate(getPageRoute("help", "SUBSCRIBING_GOOGLE_CALENDAR"), {
      state: { calendar },
    });
  } else if (user.hasValidGoogleConnection && window.location.pathname !== "/") {
    navigate(getPageRoute("home", "HOME"), {
      state: {
        recommendedCalendars: {
          calendar,
          displayMode: "addedViaGoogleConnection",
        },
      },
    })
    onSuccess();
  } else if (!user.hasValidGoogleConnection) {
    defaultFn();
  }
};

export function getCalendarApiUrl({ calendar, user }) {
  return `${calendar?.stanzaLink}/${user?.id}.ics`;
}

export const handleUpgrade = async ({
  user,
  searchParams,
  navigate,
  successUrl = null,
}) => {
  // Track the custom event for upgrading to Plus
  if (window.location.pathname === getPageRoute("help", "SUBSCRIBING_GOOGLE_CALENDAR_STEPS")) {
    customEvent({
      name: "upgrade_plus_checkout_google_calendar",
      category: "Upgrade",
      label: "Upgrade to Plus Checkout From Google Calendar Click",
      data: {
        user_id: user._id,
        user_email: user.email,
        user_handle: user.handle ?? "no_handle",
      },
    });
  } else {
    customEvent({
      name: "upgrade_plus_checkout",
      category: "Upgrade",
      label: "Upgrade to Plus Checkout Click",
      data: {
        user_id: user._id,
        user_email: user.email,
        user_handle: user.handle ?? "no_handle",
      },
    });
  }

  // Get the forward URL or set default to confirmation page
  let forward = searchParams.get("forward");
  if (!successUrl) {
    successUrl = forward || `${getPageRoute("upgrade", "PAYMENT_STANZA_PLUS_CONFIRMATION")}`;
  }

  // Add success param to the URL
  const successUrlSearchParams = new URLSearchParams(
    successUrl.split("?")?.[1] ?? ""
  );
  successUrlSearchParams.set("success", "true");
  successUrl = `${successUrl}?${successUrlSearchParams.toString()}`;
  const cancelUrl = getPageRoute("help", "FREE_TRIAL_CANCEL");

  navigate(getPageRoute("upgrade", "PAYMENT_CHECKOUT", { type: "consumer", trialPeriodDays: "7" }, { successUrl, cancelUrl }))
};
