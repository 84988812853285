import { useContext, useEffect } from "react";
import StepsScreen from "../StepsScreen.js";
import { Box, Typography } from "@mui/material";
import { UpgradeConsumerButton } from "../../upgrades/UpgradeConsumerButton.js";
import { AuthContext } from "src/utils/AuthContext.js";
import { useNavigate } from "react-router-dom";
import { getPageRoute } from "src/services/Config.js";

const HowFreeTrialWorks = () => {
  const user = useContext(AuthContext).user;
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.hasValidSubscription) {
      navigate(getPageRoute("home", "HOME"));
    }

    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);

  const steps = [
    {
      title: "Unlock full access today",
      description: (
        <>
          Add unlimited calendars, try groups, faster calendar updates and more.{" "}
          <b>Pay $0 today.</b>
        </>
      ),
      icon: "lock_open",
    },
    {
      title: "Day 5",
      description: "Get a reminder about when your trial will end.",
      icon: "event_note",
    },
    {
      title: "Day 7",
      description: "You'll be charged for your plan. Cancel anytime before.",
      icon: "credit_card",
    },
  ];

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Box
        id="container"
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "5% auto 0 auto",
          maxWidth: "400px",
          position: "relative",
          p: 2,
          px: 3.5,
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <StepsScreen
          title="How your free trial works"
          steps={steps}
          nav="back"
          sx={{ maxWidth: "400px" }}
        >
          <UpgradeConsumerButton sx={{ mt: 2 }}>
            FINISH SIGNING UP
          </UpgradeConsumerButton>
          <Typography
            variant="subtitle2"
            sx={{
              pt: 1.5,
              margin: "auto",
              color: "text.secondary",
              mb: 4,
              width: "90%",
            }}
          >
            Billed $0 today. Cancel anytime. No commitment.
          </Typography>
        </StepsScreen>
      </Box>
    </div>
  );
};

export default HowFreeTrialWorks;
