import { useEffect, useState, useMemo, useCallback } from "react";

// Singleton instance for IndexedDB connection
let dbInstance = null;

const getDBInstance = (dbName, storeName) => {
    if (dbInstance) {
        return dbInstance;
    }

    dbInstance = new Promise((resolve, reject) => {
        const request = window.indexedDB.open(dbName);
        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains(storeName)) {
                db.createObjectStore(storeName);
            }
        };
        request.onsuccess = (event) => {
            resolve(event.target.result);
        };
        request.onerror = (event) => {
            console.error("Error opening database", event.target.errorCode);
            reject(new Error("Error opening database: " + event.target.errorCode));
        };
    });

    return dbInstance;
};

export const useIndexedDB = (
    dbName,
    storeName,
    key,
    initialValue,
    setError
) => {

    const [storedValue, setStoredValue] = useState(initialValue);

    const getValue = async () => {
        try {
            const db = await getDBInstance(dbName, storeName);
            const transaction = db.transaction([storeName], "readwrite");
            const store = transaction.objectStore(storeName);
            const getRequest = store.get(key);
            getRequest.onsuccess = () => {
                if (getRequest.result !== undefined) {
                    setStoredValue(getRequest.result);
                } else {
                    const addRequest = store.add(initialValue, key);
                    addRequest.onsuccess = () => {
                        setStoredValue(initialValue);
                    };
                    addRequest.onerror = (event) => {
                        console.error("Error adding value", event.target.errorCode);
                        throw new Error("Error adding value", event.target.errorCodee);
                    };
                }
            };
            getRequest.onerror = (event) => {
                console.error("Error getting value", event.target.errorCode);
                throw new Error("Error getting value: " + event.target.errorCode);
            };
        } catch (error) {
            console.error(error);
            setError(error.message);
        }
    };

    useEffect(() => {
        getValue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setValue = async (value) => {
        try {
            const db = await getDBInstance(dbName, storeName);
            const transaction = db.transaction([storeName], "readwrite");
            const store = transaction.objectStore(storeName);
            const valueToStore = typeof value === "function" ? value(storedValue) : value;
            const putRequest = store.put(valueToStore, key);
            putRequest.onsuccess = () => {
                setStoredValue(value);
            };
            putRequest.onerror = (event) => {
                console.error("Error setting value", event.target.errorCode);
                throw new Error("Error setting value", event.target.errorCode);
            };
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const generateStatePropertyFunctions = useCallback((property) => {
        return {
            [`set${property[0].toUpperCase()}${property.slice(1)}`]: (value) => {
                setValue(prevState => {
                    const valueToSet = typeof value === 'function' ? value(prevState[property]) : value;
                    return { ...prevState, [property]: valueToSet }
                });
            }
        }
    }, [setValue]);

    const statePropertyFunctions = useMemo(() => {
        return Object.keys(storedValue).reduce((acc, property) => {
            return { ...acc, ...generateStatePropertyFunctions(property) }
        }, {})
    }, [storedValue, generateStatePropertyFunctions]);

    return [storedValue, setStoredValue, statePropertyFunctions];
};
