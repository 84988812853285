import React, { useContext, useEffect, useState, useMemo, memo } from "react";
import Navbar from "./blocks/Navbar.js";
import { Box, Tabs, Tab, Typography, Divider } from "@mui/material";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import ActionCard from "./blocks/ActionCard.js";
import Event from "./blocks/Event.js";
import Hero from "./blocks/Hero.js";
import TagsCollection from "./blocks/TagsCollection.js";
import LoadingAnimation from "./blocks/LoadingAnimation.js";
import { fetchCalendarData, getUpcomingEvents } from "src/utils/Utils.js";
import { AuthContext } from "src/utils/AuthContext.js";
import { getPageRoute } from "src/services";
import InfiniteScrollList from "./blocks/InfiniteScrollList.js";
import { useTrendingContent } from "src/utils/useTrendingContent.js";
import { useDimensions } from "src/utils/useDimensions.js"
import { revealNavbar } from "src/utils/Utils.js";
import SearchSuggestions from "./blocks/SearchSuggestions.js";
import RecommendedCalendars from "./blocks/RecommendedCalendars.js";
import { CalendarSection } from "./blocks/CalendarSection.js";
import SearchBar from "./blocks/SearchBar.js";
import { Helmet } from 'react-helmet';

function Home() {
  const { user, checkSignIn } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  let { tab, tag } = searchParams;
  tab = tab || new URLSearchParams(window.location.search).get("tab") || "discover";
  tag = tag || new URLSearchParams(window.location.search).get("tag") || "Upcoming";
  const [activeTab, setActiveTab] = useState(tab);
  const [tagsFilter, setTagsFilter] = useState([tag]);
  if (!tab || activeTab) window.history.replaceState({}, "", `?tab=${activeTab}&tag=${tagsFilter}`);
  const [needRefresh, setNeedRefresh] = useState(false);
  const navigate = useNavigate();
  const isUser = Boolean(user);
  const {
    refetchUserEvents,
    addedCalendars,
    setAddedCalendars,
    isAddedLoading,
    upcomingAddedEvents,
    initialLoad, setInitialLoad,
    isTrendingLoading,
    trendingTagEvents,
    calendarSections,
  } = useTrendingContent();
  const [eventIdSubscribed, setEventIdSubscribed] = useState(null);
  const subscribed = JSON.parse(localStorage.getItem("subscribed"));
  const [showRecommendedCalendars, setShowRecommendedCalendars] = useState(false);
  const location = useLocation();
  const recommendedCalendarsState = location.state ? location.state.recommendedCalendars ?? null : null;

  // Handles selecting Trending or Added events tab.
  const handleTabChange = async (event, key) => {
    revealNavbar();
    if (needRefresh) {
      setInitialLoad(true)
      refetchUserEvents()
      setNeedRefresh(false)
    }
    setActiveTab(key);
  };

  useEffect(() => {
    checkSignIn(true, null);
  }, []);

  const { isMediumScreen } = useDimensions();

  const upcomingTrendingEvents = useMemo(() => {
    if (tagsFilter.length > 0) {
      const filteredEvents = []
      for (let tag of tagsFilter) {
        if (!trendingTagEvents[tag]) continue;
        filteredEvents.push((trendingTagEvents[tag].events))
      }
      return filteredEvents.flat();
    }
    return [];
  }, [tagsFilter, trendingTagEvents]);

  const trendingCalendarTags = useMemo(() => {
    return [...new Set(["Upcoming", ...Object.keys(trendingTagEvents)])];
  }, [trendingTagEvents]);

  const calendarSectionsTitles = useMemo(() => {
    return Object.keys(calendarSections ?? {});
  }, [calendarSections]);

  useEffect(() => {
    if (subscribed && !isAddedLoading) {
      setAddedCalendars((prev) => {
        return [...prev, subscribed];
      })
      setShowRecommendedCalendars(true);
      localStorage.removeItem("subscribed");
    }
  }, [subscribed, setAddedCalendars, isAddedLoading])

  const onSubscribeAction = (eventId, subscribed) => {
    setNeedRefresh(true)
    setEventIdSubscribed(subscribed ? eventId : null);
  }

  const actionCardSx = { top: { xs: `calc(25vh + 60px + ${isUser ? "90px" : "0px"})`, sm: `calc(25vh + 80px + ${isUser ? "90px" : "0px"})` }, py: { sm: "80px" } };

  return (
    <div>
      <Helmet>
        <title>Stanza - Discover and add events to your calendar</title>
        <meta name="description" content="We’re an AI-powered platform for creating, discovering & subscribing to community calendars. Find events and calendars across sports, TV, entertainment, local events and more." />
      </Helmet>
      <Navbar user={user} />
      <Box sx={{ margin: "auto", maxWidth: { xs: '100%', sm: "600px" }, p: { xs: 2, md: "1rem 2rem" } }}>
        <Hero user={user}></Hero>
        <Tabs id="home-tabs" data-testid="home-tabs" value={activeTab} onChange={handleTabChange} variant={"fullWidth"} >
          <Tab label="Discover" value="discover" disableRipple />
          <Tab label="My Events" value="myevents" disableRipple />
        </Tabs>
        <Box role="tabpanel" hidden={activeTab !== 'discover'}>
          {!isTrendingLoading && !initialLoad ? (
            <>
              {showRecommendedCalendars && (
                <RecommendedCalendars sourceCalendars={addedCalendars} onSubscribeAction={null} displayMode={user?.hasValidGoogleConnection ? "addedViaGoogleConnection" : "added"} />
              )}
              {recommendedCalendarsState && (
                <RecommendedCalendars sourceCalendars={[recommendedCalendarsState.calendar]} onSubscribeAction={null} displayMode={recommendedCalendarsState.displayMode} />
              )}
              {user && <SearchBar initialFocus={true} />}
              <Typography variant="h2" sx={{ mt: 3, mb: 2 }}>
                {isMediumScreen ? "Trending on Stanza" : "Now Trending"}
              </Typography>
              <TagsCollection
                trendingCalendarTags={trendingCalendarTags}
                tagsFilter={tagsFilter}
                setTagsFilter={setTagsFilter}
              />
              {upcomingTrendingEvents.length > 0 ? (
                <InfiniteScrollList threshold={700} id="discover">
                  {upcomingTrendingEvents.map((event, index) => {

                    // It starts at 0 after the 2nd event, then increases every 5th event
                    const searchSuggestionIndex = Math.floor((index - 1) / 5);

                    // Retrieve search terms for the currently selected tag
                    const searchTerms = trendingTagEvents[tagsFilter[0]]?.searchTerms || [];
                    const searchTermsAvailable = searchTerms.length > 0;


                    // Calculate the maximum index based on sets of 3 terms
                    const maxIndex = Math.ceil(searchTerms.length / 3) - 1;
                    // Show Search Suggestions after 2nd event and every 5th event thereafter
                    const shouldShowSearchSuggestions = index === 1 || (index > 1 && (index - 1) % 5 === 0 && searchSuggestionIndex <= maxIndex);

                    // Show Calendar Section after 2nd event and every 5th event thereafter
                    const shouldShowCalendarSection = index === 1 || (index > 1 && (index - 1) % 5 === 0);
                    const calendarSectionIndex = Math.floor((index - 1) / 5);
                    const calendarSectionTitle = calendarSectionsTitles[calendarSectionIndex];
                    const calendarSection = calendarSections[calendarSectionTitle];

                    return (<>
                      <Event
                        calendar={event.calendar}
                        type="trending"
                        key={event.event.jCal[1].find((prop) => prop[0] === "uid")[3]}
                        event={event.event}
                        calendarId={event.calendarId}
                        showUpload={false}
                        position={index}
                        addedCalendars={addedCalendars}
                        setAddedCalendars={setAddedCalendars}
                        onSubscribeAction={onSubscribeAction}
                        eventIdSubscribed={eventIdSubscribed}
                        setShowRecommendedCalendars={setShowRecommendedCalendars}
                      />
                      {shouldShowSearchSuggestions && searchTermsAvailable &&
                        <SearchSuggestions key={`search-suggestion-${searchSuggestionIndex}`} searchTerms={searchTerms} index={searchSuggestionIndex} />
                      }
                      {shouldShowCalendarSection && calendarSectionTitle && tagsFilter[0] === "Upcoming" &&
                        <CalendarSection key={`calendar-section-${calendarSectionIndex}`} calendarSection={{ ...calendarSection, title: calendarSectionTitle }} onSubscribeAction={onSubscribeAction} setAddedCalendars={setAddedCalendars} setShowRecommendedCalendars={setShowRecommendedCalendars} />
                      }
                    </>
                    )
                  })}
                </InfiniteScrollList>)
                : (
                  <ActionCard
                    title="No upcoming events"
                    description="There are no events coming up."
                    primaryButtonCTA="Search for calendars"
                    primaryAction={() => navigate(getPageRoute("chat", "SEARCH"))}
                    iconName="calendar_view_day"
                    sx={actionCardSx}
                  />
                )}
            </>
          ) : (isTrendingLoading || initialLoad) && (
            <LoadingAnimation />
          )}
        </Box>
        <Box role="tabpanel" hidden={activeTab !== 'myevents'}>
          {user ? (
            (isAddedLoading || initialLoad) ? (
              <LoadingAnimation />
            ) : upcomingAddedEvents?.length > 0 ? (
              <InfiniteScrollList
                threshold={700}
                id="myevents"
              >
                {upcomingAddedEvents.map((event, index) => (
                  <Event
                    calendar={event.calendar}
                    type="added"
                    key={event.event.jCal[1].find((prop) => prop[0] === "uid")[3]}
                    event={event.event}
                    calendarId={event.calendarId}
                    showUpload={false}
                    onSubscribeAction={() => refetchUserEvents()}
                    position={`added-${index}`}
                  />
                ))}
              </InfiniteScrollList>
            ) : (
              <ActionCard
                title="No events"
                description="You haven't added any calendars yet."
                testid="user-added-no-events"
                primaryButtonCTA="Discover Calendars"
                primaryAction={() => setActiveTab("discover")}
                iconName="event"
                sx={actionCardSx}
              />
            )
          ) : (
            <ActionCard
              title="You're not signed in"
              description="You'll need to sign in to see events you've added."
              testid="guest-need-to-sign"
              primaryButtonCTA="Sign in"
              primaryAction={() => navigate(getPageRoute("auth", "SIGNIN"))}
              iconName="account_circle_off"
              sx={actionCardSx}
            />
          )}
        </Box>
      </Box>
    </div >
  );
}

export default memo(Home);
