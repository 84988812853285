import React, { useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
import { getApiRoute, getPageRoute } from "src/services";
import { Box, Typography, CircularProgress } from "@mui/material";
import { AuthContext } from "src/utils/AuthContext.js";

function GoogleCalendarCallback() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, refetchAuth } = useContext(AuthContext);
  const {
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarSeverity,
    showSnackBar,
  } = useSnackbarContext();

  useEffect(() => {
    const handleAuth = async () => {
      const params = new URLSearchParams(location.search);
      const code = params.get("code");

      if (code) {
        try {
          // Send the authorization code to the back-end
          const response = await axios.post(
            getApiRoute("auth", "CONNECT_GOOGLE_CALENDAR"),
            { code },
            { withCredentials: true } // Include cookies if needed
          );

          if (response.status === 200) {
            showSnackBar(
              setSnackbarOpen,
              setSnackbarMessage,
              setSnackbarSeverity,
              "Google Calendar connected successfully!",
              "success"
            );
            refetchAuth();
            navigate(getPageRoute("help", "GOOGLE_CALENDAR_CONNECTED")); // Redirect to a desired page
          }
        } catch (error) {
          console.error("Error connecting Google Calendar:", error);
          const errorMessage =
            error.response?.data?.message ||
            "There was an issue connecting with your Google Calendar. Please try again. If the issue persists, please email founders@stanza.co.";
          showSnackBar(
            setSnackbarOpen,
            setSnackbarMessage,
            setSnackbarSeverity,
            errorMessage,
            "error"
          );
          navigate(getPageRoute("help", "CONNECT_GOOGLE_CALENDAR")); // Redirect back to Connect Google Calendar page and ask the user to try again
        }
      } else {
        // Handle the case where no code is present
        showSnackBar(
          setSnackbarOpen,
          setSnackbarMessage,
          setSnackbarSeverity,
          "There was an issue authorizing your account. Please try again. If the issue persists, please email founders@stanza.co.",
          "error"
        );
        navigate(getPageRoute("help", "CONNECT_GOOGLE_CALENDAR")); // Redirect back to Connect Google Calendar page and ask the user to try again. 
      }
    };

    handleAuth();
  }, [
    location,
    navigate,
    showSnackBar,
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarSeverity,
  ]);

  return (
    <Box sx={{ margin: "auto", maxWidth: { xs: "100%", sm: "600px" }, p: 6 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "50vh",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
        }}
      >
        <CircularProgress thickness={2} />
        <Typography variant="subtitle2">Connecting...</Typography>
      </Box>
    </Box>
  );
}

export default GoogleCalendarCallback;
